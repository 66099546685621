import logo from "../logo.svg";
import "../App.scss";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
//Style
import "tailwindcss/tailwind.css";
import "../index.css";
import { useTranslation } from "react-i18next";

//components
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
function UserPrivacyPolicy() {
  const [t, i18n] = useTranslation("common");
  return (
    <div className="App bg-grey1">
      <div className="h-full bg-grey1">
        <HeaderComponent />
      </div>
      <div className="h-fit">
        <div className="legal-container poap-legal  font-onest text-white text-left">
          <p className="text-center ">
            <strong>Informativa</strong> <strong>sul</strong>{" "}
            <strong>trattamento</strong> <strong>dei</strong>{" "}
            <strong>dati</strong> <strong>personali</strong>{" "}
            <strong>degli</strong> <strong>Utenti</strong> <strong>del</strong>
            <strong>l&rsquo;applicazione </strong>
            <strong>POPUP Events </strong>
          </p>
          <p className="text-center">
            <strong>
              <em>(art.</em>
            </strong>{" "}
            <strong>
              <em>13</em>
            </strong>{" "}
            <strong>
              <em>del</em>
            </strong>{" "}
            <strong>
              <em>Regolamento</em>
            </strong>{" "}
            <strong>
              <em>UE</em>
            </strong>{" "}
            <strong>
              <em>679/2016)</em>
            </strong>
          </p>
          <p>
            In relazione al trattamento dei tuoi dati personali ti informiamo
            che:
          </p>
          <p className="text-center my-2">
            <strong>Titolare</strong> <strong>del</strong>{" "}
            <strong>trattamento</strong> <strong>e</strong>{" "}
            <strong>responsabile</strong> <strong>della</strong>{" "}
            <strong>protezione</strong> <strong>dei</strong>{" "}
            <strong>dati</strong> <strong>personali</strong>
          </p>
          <p>
            il Titolare del trattamento &egrave; Onlytech Industries S.r.l., via
            Monte Grappa 6/L, Thiene (VI) P.IVA 04346880240 (di seguito indicato
            anche come "Titolare") proprietaria del marchio Onlymusix.
          </p>
          <p>Il Titolare pu&ograve; essere contattato mediante:</p>
          <ul>
            <li>
              e- mail agli indirizzi{" "}
              <a
                className="text-accent underline"
                href="mailto:info@onlytechindustries.com"
              >
                info@onlytechindustries.com{" "}
              </a>
              (Posta ordinaria) ovvero{" "}
              <a
                className="text-accent underline"
                href="mailto:onlytechindustries@pec.it"
              >
                onlytechindustries@pec.it{" "}
              </a>
              (Posta certificata);
            </li>
            <li>telefono al n. 0445 1888631;</li>
            <li>
              Posta ordinaria all'indirizzo Via Monte Grappa 6/L, Thiene (VI).
            </li>
          </ul>
          <p>
            Il Titolare non ha nominato un responsabile della protezione dei
            dati personali (RPD ovvero, data protection officer, DPO).
          </p>
          <p>
            <strong>Dati</strong> <strong>trattati</strong> <strong>e</strong>{" "}
            <strong>modalit&agrave;</strong> <strong>del</strong>{" "}
            <strong>Trattamento</strong>
          </p>
          <p>
            Saranno trattati l&rsquo;indirizzo email e l&rsquo;indirizzo
            pubblico del wallet comunicati in fase di registrazione, e le
            modalit&agrave; con cui utilizzi la piattaforma. I tuoi dati saranno
            trattati in modalit&agrave; digitale con strumenti informatici e non
            sarai soggetto a processi decisionali interamente automatizzati, ivi
            compresa la profilazione.
          </p>
          <p className="text-center pt-1">
            <strong>
              Finalit&agrave; del trattamento dei dati e base giuridica
            </strong>
          </p>
          <table
            className="my-4 border-white border"
            width=""
            cellspacing="0"
            cellpadding="0"
          >
            <tbody className="my-4 border-white border">
              <tr>
                <td className="border-white border w-5" colspan="1"></td>
                <td className="border-white border" colspan="1">
                  <p>
                    <strong>Finalit&agrave;</strong> <strong>del</strong>{" "}
                    <strong>trattamento</strong> <strong>dei</strong>{" "}
                    <strong>dati</strong>
                  </p>
                </td>
                <td className="border-white border">
                  <p>
                    <strong>Base</strong> <strong>giuridica</strong>{" "}
                    <strong>del</strong> <strong>trattamento</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td className="border-white border">
                  <p className=" text-center">A</p>
                </td>
                <td className="border-white border">
                  <ul>
                    <li>
                      fornirti il POAP ed inviarti le relative comunicazioni
                    </li>
                  </ul>
                  <ul>
                    <li>rispondere alle tue richieste</li>
                  </ul>
                  <ul>
                    <li>
                      esercitare i nostri diritti derivanti dal rapporto (es.
                      rispetto termini di utilizzo)
                    </li>
                  </ul>
                </td>
                <td className="border-white border">
                  <p>Esecuzione del contratto o delle</p>
                  <p>attivit&agrave; precontrattuali richieste</p>
                </td>
              </tr>
              <tr>
                <td className="border-white border">
                  <p className=" text-center">B</p>
                </td>
                <td className="border-white border">
                  <p>adempiere ad obblighi di legge</p>
                </td>
                <td className="border-white border">
                  <p>l&rsquo;adempimento di obblighi di legge</p>
                </td>
              </tr>
              <tr>
                <td className="border-white border">
                  <p className=" text-center">C</p>
                </td>
                <td className="border-white border">
                  <ul>
                    <li>
                      monitorare come utilizzi la piattaforma per prevenire
                      attivit&agrave;
                    </li>
                  </ul>
                  <p>illecite</p>
                  <ul>
                    <li>
                      informarti su novit&agrave; e sviluppi del servizio POAP
                      richiesto
                    </li>
                  </ul>
                </td>
                <td className="border-white border">
                  <p>Legittimo interesse:</p>
                  <p>
                    -tutelare noi e te nell&rsquo;utilizzo della piattaforma;
                  </p>
                  <p>-informarti sul servizio che ci hai richiesto</p>
                </td>
              </tr>
              <tr>
                <td className="border-white border">
                  <p className=" text-center">D</p>
                </td>
                <td className="border-white border">
                  <ul>
                    <li>inviarti la newsletter di POP-UP Events</li>
                  </ul>
                  <ul>
                    <li>
                      inviarti comunicazioni commerciali relative ai servizi di
                      Onlytech Industries S.r.l.
                    </li>
                    <li>
                      Comunicare il tuo indirizzo e-mail agli organizzatori
                      dell&rsquo;evento e ad i partner tecnici
                    </li>
                  </ul>
                </td>
                <td className="border-white border">
                  <p>consenso</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="text-center my-2">
            <strong>Conseguenze</strong> <strong>della</strong>{" "}
            <strong>mancata</strong> <strong>comunicazione</strong>{" "}
            <strong>dei</strong> <strong>dati</strong>{" "}
            <strong>personali</strong>
          </p>
          <p>
            La comunicazione dei tuoi dati (email ed indirizzo pubblico del
            wallet) &egrave; necessaria per fornirti il POAP, pertanto in caso
            di mancata comunicazione il POAP non potr&agrave; esserti fornito.
            Per le ulteriori finalit&agrave; la comunicazione &egrave;
            facoltativa.
          </p>
          <p className="text-center my-2">
            <strong>Conservazione</strong> <strong>dei</strong>{" "}
            <strong>dati</strong>
          </p>
          <p>
            Conserveremo i dati che ci hai fornito per le finalit&agrave; A, B e
            C fino a quando cancellerai il tuo account dall&rsquo;applicazione
            POP-UP EVENTS. Per le finalit&agrave; A e B i tuoi dati potrebbero
            essere conservati anche dopo la cancellazione
            dall&rsquo;Applicazione ma solamente nel caso in cui fossimo
            soggetti ad obblighi di conservazione previsti da norme di legge o
            la conservazione fosse necessaria per la tutelare i nostri diritti
            nascenti dal rapporto con te, fino alla loro estinzione. I dati
            trattati per la finalit&agrave; D verranno conservati fino a quando
            non revocherai il relativo consenso o due anni dall&rsquo;ultima
            interazione.
          </p>
          <p className="text-center my-2">
            <strong>Accesso</strong> <strong>e</strong>{" "}
            <strong>comunicazione</strong> <strong>dei</strong>{" "}
            <strong>dati</strong>
          </p>
          <p>
            I tuoi dati personali potranno essere trattati per le
            finalit&agrave; sopra indicate da:
          </p>
          <ol>
            <li>
              I nostri dipendenti e collaboratori in qualit&agrave; di
              incaricati del trattamento;
            </li>
            <li>
              I nostri fornitori di servizi e sistemi informatici, relativa
              manutenzione ecc. in qualit&agrave; di responsabili del
              trattamento a cui ci affidiamo l&rsquo;esecuzione di parte dei
              trattamenti e per la gestione dei nostri sistemi informatici.
              L&rsquo;elenco aggiornato dei responsabili &egrave; disponibile
              presso la nostra sede.
            </li>
          </ol>
          <p>
            I tuoi dati, con il tuo consenso, potranno essere comunicati anche
            all&rsquo;organizzatore dell&rsquo;evento che li tratter&agrave;
            quali titolare autonomo.&nbsp;
          </p>
          <p>
            Ulteriori comunicazioni avverranno solo in caso di obblighi di legge
            o provvedimenti dell&rsquo;autorit&agrave; giudiziaria ovvero per
            l&rsquo;esercizio o la tutela dei nostri diritti.
          </p>
          <p>
            Web3Auth &egrave; titolare autonomo del trattamento, al quale non
            forniamo alcun dato, per tanto si rimanda alla relativa informativa
            fornita in fase di registrazione agli stessi per conoscere le
            modalit&agrave; di trattamento.
          </p>
          <p>
            Anche se non dipende da noi, ma dal suo funzionamento, ti ricordiamo
            che tutto quanto viene registrato sulla blockchain normalmente non
            &egrave; modificabile o cancellabile e che se fornirai elementi per
            associare a te l&rsquo;indirizzo pubblico del tuo wallet tali
            informazioni verranno riferite a te.
          </p>
          <p className="text-center my-2">
            <strong>Trasferimenti</strong> <strong>dati</strong>{" "}
            <strong>verso</strong> <strong>paesi</strong> <strong>terzi</strong>
          </p>
          <p>
            Trasferiremo i dati presso paesi terzi solo se necessario per
            fornirti il servizio e qualora siano ivi residenti i responsabili
            esterni del trattamento a cui ci affidiamo. In tale ultimo caso, i
            fornitori saranno selezionati tra coloro professionalmente idonei ed
            il trasferimento avverr&agrave; applicando una delle garanzie
            adeguate previste dagli artt. 45 e 46 del GDPR (es. clausole tipo di
            protezione adottate dalla Commissione europea) ed eventuali misure
            integrative applicabili.
          </p>
          <p className="text-center my-2">
            <strong>I</strong> <strong>tuoi</strong> <strong>diritti</strong>
          </p>
          <p>
            Tra i tuoi diritti, salvo limitazioni di legge, rientrano quelli di:
          </p>
          <ul>
            <li>
              Chiederci <strong>l&rsquo;accesso</strong> ai tuoi dati personali,
              la conferma della loro esistenza ed alle informazioni relative al
              relativo trattamento; la <strong>rettifica</strong> dei dati
              inesatti o <strong>l</strong>
              <strong>&rsquo;integrazione</strong> di quelli incompleti.
            </li>
            <li>
              chiederci la <strong>cancellazione</strong> dei tuoi dati e la{" "}
              <strong>limitazione</strong> del trattamento al ricorrere dei
              requisiti di legge.
            </li>
            <li>
              <strong>Ricevere</strong> <strong>in</strong> <strong>un</strong>{" "}
              <strong>formato</strong> <strong>strutturato</strong> di uso
              comune e leggibile i tuoi dati personali in nostro possesso,
              qualora
            </li>
          </ul>
          <p>
            il trattamento sia effettuato con mezzi automatici, per trasmetterli
            ad altro Titolare o &ndash; se tecnicamente fallibile &ndash; di
            ottenere la trasmissione diretta da parte del Titolare ad altro
            titolare.
          </p>
          <ul>
            <li>
              <strong>revocare</strong> in ogni momento <strong>i</strong>{" "}
              <strong>consensi</strong> prestati al trattamento dei dati
              personali. Tale revoca non avr&agrave; effetti
            </li>
          </ul>
          <p>sui trattamenti che abbiamo gi&agrave; eseguito.</p>
          <ul>
            <li>
              <strong>opporti</strong>, in tutto o in parte al trattamento e al
              ricorrere dei requisiti di legge (ad esempio trattamenti basati su
              legittimo interesse nostro)
            </li>
            <li>
              <strong>proporre</strong> <strong>reclamo</strong> a
              un&rsquo;autorit&agrave; di controllo ed in particolare
              all&rsquo;Autorit&agrave; Garante per la Protezione dei Dati
              Personali .
            </li>
          </ul>
          <p>
            Potrai esercitare i tuoi diritti mediante invio di una richiesta a
            mezzo email indicando in oggetto &ldquo;Privacy &ndash; esercizio
            dei diritti dell&rsquo;interessato&rdquo; al nostro indirizzo email{" "}
            <a
              className="text-accent underline"
              href="mailto:info@onlytechindustries.com"
            >
              info@onlytechindustries.com
            </a>
            . L&rsquo;opposizione e la revoca del consenso potr&agrave; essere
            esercitata anche tramite l&rsquo;apposito link presente
            nell&rsquo;email di comunicazione di newsletter e attivit&agrave;
            promozionale. La revoca dei consensi relativi alle finalit&agrave;
            non ancora eseguite potr&agrave; avvenire anche tramite l&rsquo;area
            &ldquo;Impostazioni&rdquo; della piattaforma.
          </p>
          <p>
            Potrai consultare il testo completo del Reg. UE 2016/679 &ndash;
            Regolamento generale per la protezione dei dati personali - sul sito
            internet dell&rsquo;Autorit&agrave; Garante per la Protezione dei
            Dati ed in particolare il relativo capo III &ndash; Diritti
            dell&rsquo;interessato &ndash; artt. 12 &ndash; 23.
          </p>
          <p>Onlytech Industries S.r.l.</p>
        </div>
      </div>
      <div className=" w-full bg-grey">
        {/*footer */}
        <FooterComponent />
      </div>
    </div>
  );
}
export default UserPrivacyPolicy;
