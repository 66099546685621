import React from "react";
import googleDownload from "../static/google-play-badge.png";
import downloadAppleIt from "../static/downloadAppleIt.svg";
import googleDownloadIt from "../static/google-play-badgeit.png";
import downloadApple from "../static/downloadApple.svg";
import { useTranslation } from "react-i18next";
const DownloadSeparator = (props) => {
  const { t, i18n } = useTranslation("common");

  return (
    <>
      <div className="relative top-[68px] md:top-[83px] z-10  mx-auto ">
        {i18n.language === "en" && (
          <>
            <div className=" h-[40px] md:h-[66px] m-1"></div>
            {/*remove hidden div when the app is pubblished */}
            <div className="">
              <a
                href="https://play.google.com/store/apps/details?id=com.onlytechindustries.popup_events"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="inline w-[120px] h-[40px] md:w-[200px] md:h-[66px] m-1 shadowpoap"
                  src={googleDownload}
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/pop-up-events/id6478108364"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="inline  w-[120px] h-[40px] md:w-[200px] md:h-[66px] m-1 shadowpoap"
                  src={downloadApple}
                />
              </a>
            </div>
          </>
        )}
        {i18n.language === "it" && (
          <>
            <div className=" h-[40px] md:h-[66px] m-1"></div>
            <div className="">
              <a
                href="https://play.google.com/store/apps/details?id=com.onlytechindustries.popup_events"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="inline w-[120px] h-[40px] md:w-[200px] md:h-[66px] m-1 shadowpoap"
                  src={googleDownloadIt}
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/popup-events/id6473525658"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="inline  w-[120px] h-[40px] md:w-[200px] md:h-[66px] m-1 shadowpoap"
                  src={downloadAppleIt}
                />
              </a>
            </div>
          </>
        )}
      </div>

      <div
        x-data="{}"
        x-init="$nextTick(() => {
          let ul = $refs.words;
          ul.insertAdjacentHTML('afterend', ul.outerHTML);
          ul.nextSibling.setAttribute('aria-hidden', 'true');
        })"
        className="py-4 w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]"
      >
        <ul
          x-ref="words"
          className="text-3xl uppercase font-flytrap font-normal text-white flex items-center justify-center md:justify-start [&_li]:mx-4 animate-infinite-scroll"
        >
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
          <li className="w-max">DOWNLOAD</li>
        </ul>
      </div>
    </>
  );
};
export default DownloadSeparator;
