import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";

const CookieAcceptance = (props) => {
  const { t } = useTranslation("common");
  const [cookiesCheck, setCookiesCheck] = useState([
    {
      label: "Functional (" + t("required") + ")",
      name: "cookie-functional",
      checked: true,
      required: true,
    },
    {
      label: "Insights",
      name: "cookie-google-analytics",
      checked: false,
      required: false,
    },
    {
      label: "Marketing",
      name: "cookie-facebook-pixel",
      checked: false,
      required: false,
    },
  ]);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [showCookieAcceptance, setShowCookieAcceptance] = useState(false);
  const [specifics, setSpecifics] = useState(false);

  const showSpecifics = () => {
    setSpecifics((prevState) => !prevState);
  };

  const checkCookies = () => {
    const cookieArr = cookiesCheck
      .filter((el) => el.required)
      .map((el) => el.name); //get the names of required cookies
    //check if all required cookies are set
    for (let i = 0; i < cookieArr.length; i++) {
      const el = cookieArr[i];
      if (cookies[el] === undefined) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setShowCookieAcceptance(checkCookies());
  }, [cookies, checkCookies]);

  const acceptAllHandler = (evt) => {
    setCookiesCheck((prevState) =>
      prevState.map((el) =>
        evt.target.checked
          ? { ...el, checked: true }
          : { ...el, checked: false }
      )
    );
  };

  const singleCheck = (evt) => {
    setCookiesCheck((prevState) =>
      prevState.map((el) =>
        el.name === evt.target.name
          ? { ...el, checked: evt.target.checked }
          : el
      )
    );
  };

  const submitHandler = (evt) => {
    evt.preventDefault();
    cookiesCheck.forEach((el) => {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1); // Set expiration date to one year from now
      setCookie(el.name, el.checked, { expires: expirationDate });
    });
  };

  useEffect(() => {
    if (Object.entries(cookies).length === 0) {
      return;
    }

    if (cookies["cookie-google-analytics"] === "true") {
      // trigger google analitycts
      props.onGAAccept();
    }
    if (cookies["cookie-facebook-pixel"] === "true") {
      // trigger facebook pixel
      props.onFPAccept();
    }
  }, [cookies]);

  return (
    <React.Fragment>
      {showCookieAcceptance && (
        <div className="fixed bottom-0 z-50 mx-auto mt-5 flex w-full flex-col overflow-hidden rounded-md bg-grey p-5 text-white md:w-20">
          <div className="flex w-full items-center justify-between">
            <label htmlFor="acceptAll" className="font-bold font-onest">
              {t("cookies.acceptallcookies")}:
            </label>
            <FormGroup>
              <FormControlLabel
                control={<Switch onChange={acceptAllHandler} />}
                label=""
                name="acceptAll"
                checked={cookiesCheck.every((el) => el.checked)}
              />
            </FormGroup>
          </div>
          <hr className="mt-2 opacity-20"></hr>
          <form onSubmit={submitHandler}>
            {specifics &&
              cookiesCheck.map((el) => (
                <Cookie
                  key={el.name}
                  label={el.label}
                  name={el.name}
                  checked={el.checked}
                  required={el.required}
                  onChange={singleCheck}
                />
              ))}

            <button
              type="submit"
              className="w-full rounded-l bg-yellow-500 p-2 text-black font-onest"
            >
              {t("cookies.saveandclose")}
            </button>
            <p
              className="mt-4 block cursor-pointer text-center font-onest"
              onClick={showSpecifics}
            >
              {t("cookies.showspecifics")}
            </p>
          </form>
        </div>
      )}
    </React.Fragment>
  );
};

const Cookie = ({ label, name, checked, required, onChange }) => {
  return (
    <div className="my-2 w-full">
      <div className="flex items-center justify-between text-sm">
        <p>{label}</p>
        <div>
          <label htmlFor={name}></label>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  name={name}
                  id={name}
                  className="float-right"
                  checked={checked}
                  required={required}
                  onChange={onChange}
                />
              }
              label=""
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default CookieAcceptance;
