import logo from "../logo.svg";
import "../App.scss";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import HeaderComponent from "../components/header";
//Style
import "tailwindcss/tailwind.css";
import "../index.css";

//components
import FooterComponent from "../components/footer";
import HeroComponent from "../components/hero";
import DownloadSeparator from "../components/downloadseparator";
import MainContent from "../components/maincontent";
import EmailComponent from "../components/emailcomponent";
function Home() {
  return (
    <div className="App bg-grey1">
      <div className="h-full">
        <HeaderComponent />
        <div className="pt-7">
          {/* hero component */}
          <HeroComponent />
        </div>
        <div className="pt-5">
          {/* download separator component */}
          <DownloadSeparator />
        </div>
        <div>
          {/* main content */}
          <MainContent />
        </div>{" "}
        <div className="">
          {/* download separator component */}
          <DownloadSeparator />
        </div>
        <div>
          {/*email form compoent */}
          <EmailComponent />
        </div>
        <div className=" w-full bg-grey">
          {/*footer */}
          <FooterComponent />
        </div>
      </div>
    </div>
  );
}

export default Home;
