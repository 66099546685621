import ".././App.scss";
import React, { useEffect, useRef, useState } from "react";
//Style
import "tailwindcss/tailwind.css";
import axios from "axios";

import ".././index.css";
import { useTranslation } from "react-i18next";
import { styledToast } from "./styledtoast";
const EmailComponent = (props) => {
  const [email, setEmail] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [consultancy, consultancyNewsletter] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const emailList = [];
  const [showEmailError, setShowEmailError] = useState(null);
  const { t } = useTranslation("common");
  useEffect(() => {
    console.log(newsletter);
  }, [newsletter]);
  useEffect(() => {
    console.log(consultancy);
  }, [consultancy]);
  const sendinBlueSubscribe = async (email) => {
    if (newsletter) {
      emailList.push(24); //newsletter list
    }
    if (consultancy) {
      emailList.push(26); //consultancy list;
    }
    console.log(emailList);
    const apiKey =
      "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";
    try {
      const response = await axios.put(
        `https://api.sendinblue.com/v3/contacts/${email}`,
        {
          email,
          listIds: emailList,
        },
        {
          headers: {
            "api-key": apiKey,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Sendinblue API Response:", response.data);

      return response.data;
    } catch (error) {
      try {
        const response = await axios.post(
          "https://api.sendinblue.com/v3/contacts",
          {
            email,
            listIds: emailList,
          },
          {
            headers: {
              "api-key": apiKey,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Sendinblue API Response:", response.data);

        return response.data;
      } catch (error) {
        console.error("Sendinblue API Error:", error);
        throw error;
      }
    }
  };

  /*const sendinBlueSubscribe = (email) => {
    const SibApiV3Sdk = require("@getbrevo/brevo");
    let apiInstance = new SibApiV3Sdk.ContactsApi();

    let apiKey = apiInstance.authentications["apiKey"];
    apiKey.apiKey =

    let createContact = new SibApiV3Sdk.CreateContact();
    createContact.email = email;

    return apiInstance.createContact(createContact);
  };*/
  const validateEmail = (currentEmail) => {
    const emailIsValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(currentEmail);

    setEmailValid(emailIsValid);

    setEmail(currentEmail);
  };
  const onSubscribe = (evt) => {
    console.log(email);
    evt.preventDefault();

    setShowEmailError(true);
    validateEmail(email);

    if (emailValid === false) {
      styledToast(t("toaster.notvalid"));
      return;
    }

    sendinBlueSubscribe(email)
      .then((res) => {
        console.log("guardia");
        styledToast(t("toaster.subscribe"));
        setEmail("");
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.body &&
          err.response.body.code === "duplicate_parameter"
        ) {
          styledToast(t("toaster.error"));
        } else if (err.response && err.response.body) {
          styledToast(t("toaster.error"));
        } else {
          console.error(err);
          console.log("guardia 2");
          styledToast(t("toaster.error"));
        }
      });
  };

  return (
    <div className="h-fit my-4 lg:my-7 max-w-1440 mx-auto">
      <p className="text-white font-flytrap my-3 font-normal text-xl px-2 lg:text-3xl p-2">
        {t("emailUs.createAPoap")}
      </p>
      <form>
        <div class="flex w-11/12 xxl:w-2/3 mx-auto">
          <label
            for="search-dropdown"
            class="mb-2 text-sm font-normal text-gray-900 sr-only dark:text-white"
          >
            Your Email
          </label>
          <div
            id="dropdown-button"
            data-dropdown-toggle="dropdown"
            class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-1 md:px-4 text-sm font-medium text-center text-white font-onest border-gray-500 border-4 border-e-0 rounded-s-xl "
            type="button"
          >
            Email
          </div>

          <div class="relative w-full">
            {consultancy || newsletter ? (
              <input
                type="email"
                value={email}
                onChange={(evt) => validateEmail(evt.target.value)}
                class="block p-2 md:p-[25px] w-full z-20 text-custom text-white rounded-e-xl rounded-s-gray-100 rounded-s-2 border-gray-500 border-4  bg-grey1  border-grey"
                placeholder={t("emailUs.insertEmail")}
                required
              />
            ) : (
              <input
                type="email"
                value={email}
                onChange={(evt) => validateEmail(evt.target.value)}
                class="block p-2 md:p-[25px] w-full z-20 text-custom text-white rounded-e-xl rounded-s-gray-100 rounded-s-2 border-gray-500 border-4  bg-grey1  border-grey"
                placeholder={t("emailUs.select")}
                required
                disabled={false}
              />
            )}
            {consultancy || newsletter ? (
              <button
                type="submit"
                onClick={onSubscribe}
                class="absolute top-0 end-0 p-1 md:p-2.5 h-full w-1/3 lg:w-1/4 font-onest font-medium md:text-lg text-black rounded-xl border-black focus:outline-none bg-yellow-500"
              >
                {t("emailUs.subscribe")}
              </button>
            ) : (
              <button
                type="button"
                onClick={(evt) => {
                  styledToast(t("toaster.options"));
                }}
                class="absolute top-0 end-0 p-1 md:p-2.5 h-full w-1/3 lg:w-1/4 font-onest font-medium md:text-lg text-black rounded-xl border-black focus:outline-none bg-yellow-100"
              >
                {t("emailUs.subscribe")}
              </button>
            )}
          </div>
        </div>
      </form>
      <div className="mx-4 xl:mx-7">
        <div class="flex flex-row items-start mt-2 md:mt-4">
          {/* consultancy checkbox */}
          <input
            type="checkbox"
            checked={consultancy}
            onChange={() => {
              consultancyNewsletter(!consultancy);
            }}
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            for="default-checkbox"
            class="ms-2 text-sm font-normal text-left text-white dark:text-gray-300"
          >
            {t("emailUs.firstCheckbox")}
          </label>
        </div>
        <div class="flex items-start mt-2">
          {/* newsletter checkbox */}
          <input
            type="checkbox"
            checked={newsletter}
            onChange={() => {
              setNewsletter(!newsletter);
            }}
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            for="checked-checkbox"
            class="ms-2 text-sm font-normal text-left text-white dark:text-gray-300"
          >
            {t("emailUs.secondCheckbox")}
          </label>
        </div>
      </div>
    </div>
  );
};

export default EmailComponent;
