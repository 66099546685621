import logo from "../logo.svg";
import "../App.scss";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
//Style
import "tailwindcss/tailwind.css";
import "../index.css";

//components
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
function BuyerTerms() {
  return (
    <div className="App bg-grey1">
      <div className="h-full bg-grey1">
        <HeaderComponent />
      </div>
      <div className="h-fit">
        <div className="legal-container poap-legal  font-onest text-white text-left">
          <p>
            <strong>
              TERMINI D&rsquo;USO DELL&rsquo;APPLICAZIONE MOBILE POP-UP Events
            </strong>
          </p>
          <p>
            I presenti termini di servizio regolamentano l'applicazione mobile
            POP-UP Events.
          </p>
          <ol>
            <li>
              <strong> Definizioni</strong>
            </li>
          </ol>
          <p>
            1.1. Nel presente contratto i seguenti termini avranno il
            significato riportato accanto:
          </p>
          <p>
            <strong>Applicazione:</strong> l&rsquo;applicazione POP-up Events
            disponibile su Apple App Store e Google Play Store
          </p>
          <p>
            <strong>Organizzatore</strong>: la persona fisica o giuridica che
            agisce nell'esercizio della propria attività imprenditoriale,
            commerciale, artigianale o professionale, ovvero un suo
            intermediario;
          </p>
          <p>
            <strong>POAP (</strong>"proof of attendance protocol&rdquo;
            <strong>)</strong>: un certificato di partecipazione ad un evento
            ospitato su blockchain, e come tale I POAP sono destinati a fungere
            da ricordo. I POAP non possono essere modificati, né venduti, o
            cancellati attraverso la nostra piattaforma; Anche se tecnicamente è
            possibile vendere un POAP NFT, non c'è garanzia di ottenere un
            profitto e non è questo il loro scopo.
          </p>
          <p>
            <strong>Claim</strong>. Per claim si intende il riscatto{" "}
            <strong>gratuito </strong>della proprietà del POAP e quindi la
            facoltà di esercitare i diritti e/o le facoltà incorporate nel NFT,
            come disciplinate e nei limiti della Licenza.
          </p>
          <p>
            <strong>Gestore</strong>: Onlytech Industries S.r.l. proprietaria e
            gestore dell&rsquo;Applicazione ;&nbsp;
          </p>
          <p>
            <strong>Servizio</strong>: Il Complesso dei servizi forniti dal
            Gestore attraverso l'Applicazione.&nbsp;
          </p>
          <p>
            <strong>Utente</strong>: persona fisica che utilizza l'Applicazione
            per riscattare il POAP
          </p>
          <ol start="2">
            <li>
              <strong> Oggetto</strong>
            </li>
          </ol>
          <p>
            <br />
            2.1. L'accesso e l'utilizzo dell&rsquo;applicazione POP-UP Events
            (d'ora in poi anche "Applicazione") di proprietà di e gestito da
            Onlytech Industries S.r.l. via Monte Grappa 6/L, Thiene (VI) P.IVA
            04346880240 (d'ora in poi anche "Gestore"), per l&rsquo;ottenimento
            di un POAP sono regolati dai presenti termini d&rsquo;uso che
            l&rsquo;utente prima di accedere all'Applicazione deve aver letto,
            compreso ed accettato.
            <br /> <br />
            2.2. Il Gestore potrà modificare ed aggiornare tali termini mediante
            notifica agli utenti registrati e pubblicazione della versione
            aggiornata nell&rsquo;Applicazione con l&rsquo;indicazione della
            data di entrata in vigore. L'utilizzo dell&rsquo;Applicazione dopo
            la data di entrata in vigore delle modifiche comporterà
            l&rsquo;accettazione delle stesse pertanto, in caso di modifica,
            l&rsquo;Utente è tenuto a consultare i nuovi termini d&rsquo;uso che
            devono essere compresi prima di proseguire con l&rsquo;utilizzo
            dell&rsquo;Applicazione.
          </p>
          <ol start="3">
            <li>
              <strong> Requisiti per l&rsquo;accesso</strong>
            </li>
          </ol>
          <p>
            <br />
            3.1. Per accedere all&rsquo;applicazione ed utilizzare il servizio è
            necessario avere almeno 16 anni <br />
            <strong>3.2. </strong>Per utilizzare l&rsquo;Applicazione e i
            relativi servizi l&rsquo;Utente deve creare un Wallet tramite il
            servizio di autenticazione web3auth. Come precisato al punto{" "}
            <strong>Link, prestazioni e siti di terzi </strong>, Il Gestore non
            ha un collegamento con tali servizi né controllo della relativa
            attività.
          </p>
          <ol start="4">
            <li>
              <strong> Account personale</strong>
            </li>
          </ol>
          <p>
            <br />
            4.1. L'Utente potrà registrarsi all&rsquo;Applicazione per usufruire
            dei relativi servizi tramite un'area personale denominata
            "Impostazioni".
            <br /> <br />
            4.2. Per registrarsi l'utente potrà utilizzare il servizio Web3auth
            che tramite l&rsquo;accesso ad un servizio già utilizzato (ad es.
            google, apple, ecc. come indicati nella pagina di registrazione)
            fornirà anche un wallet per ricevere il POAP e conservarlo.
            <br /> <br />I dati di accesso e in particolare la chiave privata
            del wallet sono strettamente personali e l&rsquo;Utente è obbligato
            a preservarne la riservatezza e segretezza impegnandosi a non
            comunicarli e trasferirli a terzi e a informare prontamente il
            Gestore di qualsiasi eventuale loro uso non autorizzato seguendo le
            indicazioni riportate sull&rsquo;Applicazione, affinché il Gestore
            possa sospendere in via cautelativa l'account in attesa di
            verifiche. A tal fine l&rsquo;utente dovrà assicurarsi anche che
            nessun terzo possa accedere alla propria casella e-mail.
            <br /> <br />
            Qualora il Gestore sospetti un accesso non autorizzato all'account
            dell'Utente si riserva la facoltà di bloccarlo senza che
            l&rsquo;Utente questi abbia nulla a pretendere nei confronti del
            Gestore.
          </p>
          <ol start="5">
            <li>
              <strong> Proprietà intellettuale e segni distintivi</strong>
            </li>
          </ol>
          <p>
            <br />
            5.1. I materiali presenti sull&rsquo;Applicazione (ad esempio
            fotografie, immagini, disegni, figure, loghi, video, animazioni,
            suoni, musiche, testi, documenti ed ogni altro materiale, in
            qualsiasi formato, pubblicato sull&rsquo;Applicazione) ed la
            Applicazione stessa (ad esempio menu, layouts e design del sito e
            delle singole pagine web, la relativa grafica e i colori, i
            caratteri, e qualsiasi aspetto del relativo funzionamento quali ad
            esempio i diagrammi, i processi, le funzioni, gli strumenti e il
            software in generale incorporato nell&rsquo;Applicazione) sono
            protetti dal diritto d'autore e da ogni altro diritto di proprietà
            intellettuale del Gestore o di eventuali terzi licenzianti.
            <br /> <br />
            5.2. L'utente potrà esclusivamente accedere, visualizzare
            l'Applicazione ed i suoi contenuti e utilizzare i relativi servizi.
            Sono fatte salve le operazioni tecniche strettamente necessarie per
            la visualizzazione e fruizione dell&rsquo;Applicazione e dei suoi
            contenuti, prive di alcuna rilevanza economica.
            <br /> <br />
            5.3. È espressamente vietata la riproduzione dell&rsquo;Applicazione
            e dei materiali ivi presenti in qualsiasi forma, totale o parziale
            senza espressa autorizzazione scritta da parte del Gestore o dai
            titolari dei diritti sulle singole opere contenute
            nell&rsquo;Applicazione e, qualora fosse concessa
            l&rsquo;autorizzazione, dovranno comunque essere utilizzate per
            scopi leciti e nel rispetto del diritto d'autore e degli altri
            diritti di proprietà intellettuale dei relativi titolari in modo da
            non ledere alcun diritto né cagionare alcun danno.
            <br /> <br />
            5.4. Tutti i marchi ed i segni distintivi presenti
            sull&rsquo;Applicazione, anche relativi alle singole attività svolte
            dal Gestore, sono di esclusiva proprietà dello stesso o dei relativi
            licenzianti pertanto, salva espressa autorizzazione scritta da parte
            del Gestore o dei relativi licenzianti, qualsiasi uso è vietato a
            qualsiasi titolo, anche personale.
            <br /> <br />
            5.5. 3. Il dominio popupevents.xyz così come le varie declinazioni
            ed i sottodomini sono di proprietà del Gestore. È vietato qualsiasi
            utilizzo o riferimento allo stesso salva specifica autorizzazione
            scritta del titolare o dei titolari.
          </p>
          <ol start="6">
            <li>
              <strong> Linee guida utilizzo Applicazione</strong>
            </li>
          </ol>
          <p>
            6.1. Nell&rsquo;utilizzare l&rsquo;Applicazione l&rsquo;utente dovrà
            rispettare le indicazioni contenute nella area supporto (Guide,
            domande frequenti - FAQ ecc.) ed in particolare dovrà astenersi da:
          </p>
          <p>
            Utilizzare l&rsquo;Applicazione per scopi diversi
            dall&rsquo;ottenimento del POAP, né potrà utilizzarlo per scopi
            illeciti o in violazione di norme;
          </p>
          <p>
            <br />
            Cercare di vendere o cedere il POAP a terzi;
          </p>
          <p>
            Caricare o inserire nell'Applicazione dati, informazioni o contenuti
            di cui non si possiedono i relativi diritti, in violazione di norme
            di legge, regolamenti o diritti di terzi, ivi compresi dati
            personali, ovvero ancora in violazione delle Linee guida
            dell&rsquo;Applicazione;
          </p>
          <p>
            Danneggiare l&rsquo;Applicazione o interferire con il relativo
            funzionamento o con la fruizione da parte degli altri utenti, con
            qualsiasi mezzo;
          </p>
          <p>
            Aggirare, disattivare ovvero interferire in qualsiasi modo con gli
            applicativi correlati alla sicurezza dell'Applicazione o altri
            applicativi che prevengano, limitino ovvero restringano l'utilizzo
            ovvero la copia di qualsiasi materiale presente sullo stesso o altre
            misure che l'Applicazione possa utilizzare per prevenire accessi non
            autorizzati.
          </p>
          <ol start="7">
            <li>
              <strong> Limitazioni all'erogazione del servizio</strong>
            </li>
          </ol>
          <p>
            7.1. Il Gestore si riserva la facoltà di sospendere il servizio per
            attività di manutenzione o implementazione di nuove funzionalità
            dandone avviso tramite l&rsquo;Applicazione stessa ove possibile
            senza che questo dia diritto ad alcun risarcimento agli Utenti.
            <br /> <br />
            7.2. Il Gestore si riserva altresì la facoltà di sospendere
            l&rsquo;attività dell&rsquo;Applicazione senza preavviso,
            temporaneamente o definitivamente. L&rsquo;eventuale sospensione
            dell&rsquo;attività dell&rsquo;Applicazione, anche a titolo
            definitivo non influirà sugli acquisti eseguiti tramite
            l&rsquo;Applicazione, anche dei POAP, i quali rimarranno trascritti
            sulla blockchain e disponibili sui relativi Wallet dove sono
            conservati, e non darà diritto ad alcun risarcimento agli Utenti. In
            caso di cessazione del servizio a titolo definitivo, il Gestore si
            riserva la facoltà di comunicare agli utenti le eventuali modalità
            per poter visualizzare i POAP reclamati in piattaforma.
            <br /> <br />
            7.3. Il funzionamento dell&rsquo;Applicazione dipende anche da
            servizi di terzi su cui il Gestore non ha alcuna possibilità di
            controllo.
          </p>
          <ol start="8">
            <li>
              <strong> Link, prestazioni e siti di terzi</strong> <br />
              8.1. L&rsquo; Applicazione non fornisce servizi di Wallet per il
              pagamento e la conservazione di POAP e NFT o di Exchange per
              ricaricare il Wallet di valuta virtuale o cambiare valute legali
              in valute virtuali e viceversa, rinviando l&rsquo;utente a servizi
              terzi. L&rsquo;utilizzo di tali servizi collegati
              dall&rsquo;utente all&rsquo;Applicazione al momento della
              registrazione viene fornito da terzi. L&rsquo;Acquirente è quindi
              consapevole che tali servizi sono regolati da condizioni di
              utilizzo e termini legali reperibili sui relativi siti internet e
              che su tali servizi il Gestore non ha alcun controllo e non avrà
              alcuna responsabilità per l&rsquo;utilizzo che l&rsquo;Utente farà
              degli stessi, anche qualora subisse danni.
            </li>
          </ol>
          <p>
            8.2. L&rsquo;Applicazione può contenere collegamenti a servizi e
            siti web di terzi (es. Web3auth e metamask) sui quali servizi il
            Gestore non ha alcun controllo. L&rsquo;Acquirente è quindi
            consapevole che tali servizi sono regolati da condizioni di utilizzo
            e termini legali reperibili sui relativi siti internet che dovrà
            aver letto, compreso ed accettato prima di utilizzarli, comprese le
            relative privacy policy, in quanto le presenti condizioni d'uso e la
            relativa privacy policy si riferiscono unicamente
            all&rsquo;Applicazione. L&rsquo;Acquirente prima di utilizzare tali
            servizi deve essere altresì consapevole che il Gestore non avrà
            alcuna responsabilità per l&rsquo;utilizzo che l&rsquo;Utente farà
            degli stessi, anche qualora subisse danni.
          </p>
          <ol start="9">
            <li>
              <strong> Avviso sulle criptovalute</strong>
            </li>
          </ol>
          <p>
            9.1. I <strong>POAP </strong>non sono criptovalute né richiedono un
            pagamento in criptovalute. Nonostante l&rsquo;Applicazione non
            intermedi l&rsquo;acquisto di criptovalute, o il relativo cambio con
            valute legali (es. l&rsquo;EURO), si richiama l&rsquo;attenzione
            dell&rsquo;utente prima di acquistare criptovalute su:
          </p>
          <ul style={{ listStyleType: "circle", paddingInlineStart: "48px" }}>
            {" "}
            <li aria-level="1">
              I potenziali rischi che comporta l&rsquo;acquisto di tali valute o
              il cambio di valute legali (ad esempio EURO) in valute virtuali
              per i repentini cambi del relativo valore, l&rsquo;assenza ad oggi
              di una regolamentazione puntuale che tuteli i possessori e
              l&rsquo;assenza di autorità di regolamentazione e controllo;
            </li>
            <li aria-level="1">
              Il valore di una unità di valuta virtuali può essere molto diverso
              da quello di una unità di valuta legale. Per verificare il
              relativo valore aggiornato si rimanda a siti internet ad hoc come
              ad esempio marketcap.com/ o similari.
            </li>
          </ul>
          <p>
            Per approfondire i potenziali rischi legati all&rsquo;utilizzo di
            valute virtuali si invita a consultare i siti internet di Consob e
            della Banca d&rsquo;Italia ed i relativi comunicati.
          </p>
          <p>
            9.2. L&rsquo;Applicazione non fornisce né promuove prodotti
            finanziari o investimenti in valute virtuali.
          </p>
          <ol start="10">
            <li>
              <strong> Supporto Utenti</strong>
            </li>
          </ol>
          <p>
            10.1. Il Gestore si impegna a riscontrare tempestivamente, e ove
            possibile nel termine massimo di 15 giorni lavorativi, le richieste
            di informazioni e le segnalazioni ricevute dagli Utenti e relative
            agli acquisti effettuati, al funzionamento della Piattaforma o ai
            contenuti presenti sulla stessa. Ogni richiesta relativa ai Prodotti
            o alla Vendita verrà inoltrata al relativo Venditore. Le richieste e
            le segnalazioni dovranno essere fate:
          </p>
          <ol>
            <li>a mezzo email all&rsquo;indirizzo:</li>
          </ol>
          <p>
            info@onlytechindustries.com per aspetti relativi al funzionamento
            della piattaforma, ai contenuti presenti sulla stessa o agli
            acquisti effettuati;
            <br />
            <br />
          </p>
          <ol start="11">
            <li>
              <strong> Ruolo e responsabilità del Gestore</strong>
              <strong>
                <br />
              </strong>
            </li>
          </ol>
          <p>
            11.1. Il Gestore ha interesse affinché tutte le informazioni
            presenti sull&rsquo;Applicazione siano corrette, complete e
            aggiornate tuttavia, pur riservandosi, ove possibile, di effettuare
            controlli circa la veridicità delle informazioni caricate da terzi
            (es. fornitori e venditori di NFT i quali caricano anche i relativi
            Contenuti e descrizioni), Il Gestore dell&rsquo;Applicazione non ha
            alcun controllo sugli stessi e non sarà responsabile, nemmeno quando
            i dati inseriti nelle descrizioni dei Prodotti fossero errati o
            mendaci o il venditore stia violando diritti di terzi, non potendo
            garantirne la titolarità, correttezza o veridicità. Al fine di
            permettere al Gestore di esercitare un controllo più efficace,
            l&rsquo;Utente si impegna a comunicargli qualora dovesse riscontrare
            errori, informazioni inesatte o errate nelle descrizioni o
            comportamenti sospetti di Venditori e altri utenti
            dell&rsquo;Applicazione. In seguito a tali segnalazioni il Gestore
            si riserva gli approfondimenti e le azioni che riterrà più
            opportune.
          </p>
          <p>
            11.2. Il Gestore ha interesse ad evitare che siano pubblicati
            sull&rsquo;Applicazione Contenuti illeciti, che violino le linee
            guida dell'Applicazione o che possano essere ritenuti lesivi dagli
            utenti. In ogni caso, qualora uno o più Contenuti siano ritenuti
            lesivi, anche di eventuali diritti di terzi, l'Utente interessato
            potrà segnalare tale circostanza al Gestore, il quale tuttavia
            avverte che, data la varietà delle espressioni artistiche e la
            percezione soggettiva delle stesse, ogni eventuale accesso ai
            Contenuti considerati lesivi o offensivi avviene da parte
            dell'utente a proprio insindacabile giudizio ed a sua esclusiva e
            personale responsabilità. In seguito a tali segnalazioni il Gestore
            si riserva gli approfondimenti e le azioni che riterrà più
            opportune.
          </p>
          <p>
            11.3. Il Gestore non potrà essere ritenuto in alcun modo
            responsabile, direttamente o indirettamente, per eventuali danni
            dovuti al mancato rispetto da parte dell'Utente dei presenti Termini
            d&rsquo;uso dell'Applicazione e delle relative Linee guida.
          </p>
          <ol start="12">
            <li>
              <strong>
                {" "}
                Utilizzo del Applicazione e degli strumenti e responsabilità
                dell'utente
              </strong>
            </li>
          </ol>
          <p>
            L'accesso e l'uso dell&rsquo;Applicazione e dei relativi servizi può
            avvenire esclusivamente nel rispetto nel rispetto dei presenti
            termini d&rsquo;uso e delle Linee guida, ed in particolare dei
            requisiti di cui al punto 3{" "}
            <strong>Requisiti per l&rsquo;accesso </strong>e per usi personali
            estranei a qualsiasi attività commerciale, imprenditoriale e
            professionale. L'utente è personalmente responsabile per l'uso
            dell&rsquo;Applicazione e dei relativi servizi nonché di ogni
            conseguenza dannosa o pregiudizio che dovesse derivare a sé stesso,
            al Gestore o a terzi a seguito del non corretto utilizzo dello
            stesso. Il Gestore si riserva la facoltà di sospendere o eliminare
            l&rsquo;account degli utenti che violino le presenti condizioni d'
            uso, le Linee guida ovvero che compiano un uso illecito o scorretto
            dell&rsquo;Applicazione.
          </p>
          <p>
            12.1. L&rsquo;Utente è responsabile della custodia del proprio
            Wallet contenente i POAP reclamati, della relativa chiave privata e
            delle credenziali di accesso per autorizzare le transazioni, essendo
            consapevole che l&rsquo;eventuale smarrimento delle stesse potrebbe
            comportare l&rsquo;impossibilità di accedere al Wallet e disporre
            quindi dei POAP reclamati. Il Gestore non fornisce tale servizio e
            non è in possesso né della password dell&rsquo;utente né della
            chiave privata del relativo Wallet pertanto in caso di smarrimento
            non potrà fornirne copia. L&rsquo;Utente pertanto sarà responsabile
            per eventuali conseguenze dannose o pregiudizi che dovessero
            derivare a sé stesso, al Gestore o a terzi a seguito del non
            corretto utilizzo, dello smarrimento o della sottrazione di tali
            informazioni.
          </p>
          <ol start="13">
            <li>
              <strong> Cessione dell&rsquo;Applicazione a terzi</strong>
            </li>
          </ol>
          <p>
            13.1. Il Gestore si riserva la facoltà di cedere l'Applicazione a
            terzi senza che ciò dia diritto ad alcun risarcimento danni a favore
            dell&rsquo;Utente.
          </p>
          <ol start="14">
            <li>
              <strong> Durata</strong>
            </li>
          </ol>
          <p>
            14.1. I presenti termini d&rsquo;uso regoleranno l&rsquo;utilizzo
            dell&rsquo;Applicazione da parte dell&rsquo;Utente. Qualora
            l&rsquo;Utente cessasse di utilizzare il Applicazione o cancellasse
            il proprio account sullo stesso, rimarranno comunque in vigore i
            contratti conclusi che regolamentano gli acquisti effettuati tramite
            l'Applicazione.
          </p>
          <ol start="15">
            <li>
              <strong> Trattamento dei dati</strong>
            </li>
          </ol>
          <p>
            15.1. I dati dell'Utente sono trattati come specificato in fase di
            registrazione e nell'apposita sezione contenente l'informativa ai
            sensi dell'art. 13 Regolamento UE 2016/679 (Privacy Policy). Per
            ogni informazione sul trattamento dei dati personali si invita
            l&rsquo;Utente a consultare anche la privacy policy
            dell&rsquo;Applicazione al link presente nella home page.
          </p>
          <ol start="16">
            <li>
              <strong> Legge applicabile e foro competente</strong> <br />
              16.1. Il presente contratto è regolato dalla legge italiana.
            </li>
          </ol>
          <ul style={{ listStyleType: "circle", paddingInlineStart: "48px" }}>
            {" "}
            <li aria-level="1">
              16.2. Ogni controversia relativa all&rsquo;applicazione,
              esecuzione e interpretazione del presente documento è competente
              il foro del luogo in cui l&rsquo;Utente risiede o ha eletto
              domicilio qualora sia consumatore. In tal caso, qualora egli
              voglia presentare un reclamo, o lo abbia già presentato e non sia
              stata risolta la controversia, l&rsquo;Utente potrà avvalersi
              della piattaforma europea per la risoluzione on-line delle
              controversie dei consumatori (c.d. piattaforma ODR) consultabile
              al seguente indirizzo{" "}
              <a
                className="text-accent inline break-words"
                href="https://ec.europa.eu/consumers/odr/."
              >
                https://ec.europa.eu/consumers/odr/.
              </a>{" "}
              Tramite tale procedura potrà notificare un reclamo al Gestore ed
              avviare una procedura di risoluzione on-line della controversia
              tramite uno degli organismi presenti nell&rsquo;elenco pubblicato
              sulla stessa. Sono fatti salvi in ogni caso il diritto
              dell&rsquo;Utente e del Gestore di adire il Giudice competente per
              la controversia, in caso di abbandono o esito negativo della
              procedura di composizione extragiudiziale delle controversie.
            </li>
            <li aria-level="1">
              16.3. Qualora l&rsquo;Acquirente risieda in uno stato membro
              dell&rsquo;Unione Europea diverso dall&rsquo;Italia ed il valore
              della controversia sia inferiore ad &euro;2.000,00, potrà
              ricorrere al procedimento europeo per le controversie di modesta
              entità istituito dal Reg. (CE) n. 861/2007 del Consiglio,
              dell&rsquo;11 luglio 2007 consultabile al sito{" "}
              <a
                className="text-accent underline"
                href="http://www.eur-lex.europa.eu"
              >
                www.eur-lex.europa.eu
              </a>
              .
            </li>
            <li aria-level="1">
              Le presenti condizioni si applicano a far data dal
              12/12/2023.&nbsp;
            </li>
          </ul>
        </div>
      </div>
      <div className=" w-full bg-grey">
        {/*footer */}
        <FooterComponent />
      </div>
    </div>
  );
}
export default BuyerTerms;
