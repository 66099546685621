import ".././App.scss";
//Style
import "tailwindcss/tailwind.css";
import ".././index.css";
import React from "react"; // Removed unused imports
import logo from "../static/ICONA-APP-STORE-22.png";
import background from "../static/SFONDO-LANDING-POAP.png";
import logoblack from "../static/ICONA-APP-STORE-1.png";
import torino from "../static/poaptorino.png";
import primomaggio from "../static/primomaggio2023.png";
import tp from "../static/tp10anniversaryshow.png";
import crowdphoto from "../static/hero1.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper"; // Import Swiper styles
import "swiper/css";
import makeyoureventpoap from "../static/SCRITTA-MAKE-YOUR-POAP.png";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { useTranslation } from "react-i18next";
//images

const MainContent = (props) => {
  const { t } = useTranslation("common");
  return (
    <div className="h-fit bg-grey1 my-6">
      <div className="flex flex-col md:flex-row justify-center max-w-1440 mx-auto">
        <div className="flex flex-col mx-auto xl:ml-6 w-10/12 md:w-3/5 my-auto text-white gap-5 font-normal font-flytrap ">
          <p className="mx-auto flex text-left text-3xl lg:text-5xl xl:text-6xl pt-1">
            {t("main.title")}
          </p>

          <div className="flex md:pl-4 xl:pl-0">
            <div className="bg-grey h-fit py-0 pr-1 md:pr-6 w-full xl:w-[750px] rounded-[20px] mx-auto ">
              <div className="flex flex-row">
                <p className="flex relative top-[7px] text-7xl lg:text-9xl left-[0px] lg:left-[0px]">
                  1
                </p>
                <p className="flex text-left text-xs ml-[39px] lg:ml-[70px] lg:text-xl text-white font-normal font-flytrap my-auto">
                  {t("main.firstEntry")}
                </p>
              </div>
            </div>
          </div>
          <div className="flex md:pl-4 xl:pl-0">
            {" "}
            <div className="bg-grey h-fit py-0 pr-1 md:pr-6 w-full xl:w-[750px] rounded-[20px] mx-auto ">
              <div className="flex flex-row">
                <p className="flex relative top-[7px] text-7xl lg:text-9xl  left-[-20px] lg:left-[-30px]">
                  2
                </p>
                <p className="flex text-left text-xs lg:text-xl text-white font-normal font-flytrap my-auto">
                  {t("main.secondEntry")}
                </p>
              </div>
            </div>
          </div>
          <div className="flex md:pl-4 xl:pl-0">
            {" "}
            <div className="bg-grey h-fit py-0 pr-1 md:pr-6 w-full xl:w-[750px] rounded-[20px] mx-auto ">
              <div className="flex flex-row">
                <p className="flex relative top-[7px] text-7xl lg:text-9xl  left-[-15px] lg:left-[-30px]">
                  3
                </p>
                <p className="flex text-left text-xxs lg:text-lg text-white font-normal font-flytrap my-auto">
                  {" "}
                  {t("main.thirdEntry")}
                </p>
              </div>
            </div>
          </div>
          <div className="flex md:pl-4 xl:pl-0">
            {" "}
            <div className="bg-grey h-fit py-0 pr-1 md:pr-6 w-full xl:w-[750px] rounded-[20px] mx-auto ">
              <div className="flex flex-row">
                <p className="flex relative top-[7px] text-7xl lg:text-9xl  left-[-15px] lg:left-[-30px]">
                  4
                </p>
                <p className="flex text-left text-xs lg:text-xl text-white font-normal font-flytrap my-auto">
                  {" "}
                  {t("main.fourthEntry")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex mx-auto my-auto w-full md:w-1/3 text-white font-normal font-flytrap ">
          <div className=" mx-auto md:m-1 md:mt-4">
            {/*desktop swiper */}
            <div className="hidden md:flex mx-auto md:h-[430px] lg:h-[650px] xl:h-[880px] xxl:w-[450px] md:my-auto rounded-xl  overflow-hidden">
              <Swiper
                direction={"vertical"}
                slidesPerView={2}
                initialSlide="0"
                spaceBetween={200}
                mousewheel={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                centeredSlides={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper pt-4"
              >
                <SwiperSlide>
                  {" "}
                  <img className="shadowpoap rounded-xl" src={primomaggio} />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img className="shadowpoap rounded-xl" src={torino} />
                </SwiperSlide>

                <SwiperSlide>
                  <img className="shadowpoap rounded-xl" src={tp} />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="flex w-15 mx-auto my-auto pt-4 md:hidden">
              {/*mobile swiper */}
              <Swiper
                slidesPerView={1}
                loop={true}
                initialSlide="0"
                spaceBetween={10}
                centeredSlides={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img className="shadowpoap rounded-xl" src={tp} />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img className="shadowpoap rounded-xl" src={primomaggio} />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img className="shadowpoap rounded-xl" src={torino} />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainContent;
