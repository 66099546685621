import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_it from "./translations/it/common.json";
import common_en from "./translations/en/common.json";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CookieAcceptance from "./components/CookieAcceptance";
import { initializeFP, initializeGA } from "./components/Trackers";
//pages
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import BuyerTerms from "./pages/BuyerTerms";
import General from "./pages/General";
import CreatorUsage from "./pages/CreatorUsage";
import { Page404 } from "./pages/Page404";
import UserPrivacyPolicy from "./pages/UserPrivacyPolicy";
import WithTrackers from "./components/Trackers";
import { Toaster } from "react-hot-toast";
i18next.init({
  lng: "it",
  interpolation: { escapeValue: false },
  resources: {
    en: {
      common: common_en,
    },
    it: {
      common: common_it,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Toaster position="top-right" />

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/legal/privacy-policy" element={<Privacy />} />
          <Route
            path="/legal/user-privacy-policy"
            element={<UserPrivacyPolicy />}
          />
          <Route path="/legal/general-sell-conditions" element={<General />} />
          <Route path="/legal/buyer-terms-of-use" element={<BuyerTerms />} />
          <Route
            path="/legal/creator-usage-application"
            element={<CreatorUsage />}
          />
          <Route
            path="/legal/creator-usage-application"
            element={<CreatorUsage />}
          />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Router>
      <CookieAcceptance onGAAccept={initializeGA} onFPAccept={initializeFP} />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
