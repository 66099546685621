import logo from "../logo.svg";
import "../App.scss";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
//Style
import "tailwindcss/tailwind.css";
import "../index.css";
//component
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
export function Page404() {
  const { t } = useTranslation("common");
  return (
    <div className="App bg-grey1">
      <div className="h-full bg-grey1">
        <HeaderComponent />
      </div>
      <div className="h-40">
        <div className="flex-col">
          <div className="omx-container absolute top-0 bottom-0 left-0 right-0 flex-col flex items-center justify-center">
            <h1 className="text-6xl font-bold font-flytrap text-white">
              {" "}
              {t("page404.error")} 404
            </h1>
            <p className="text-white font-flytrap">
              {t("page404.pagenotfound")}
            </p>
          </div>
        </div>
      </div>
      <div className=" w-full bg-grey">
        {/*footer */}
        <FooterComponent />
      </div>
    </div>
  );
}
