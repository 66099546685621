import logo from "../logo.svg";
import "../App.scss";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
//Style
import "tailwindcss/tailwind.css";
import "../index.css";

//components
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
function CreatorUsage() {
  return (
    <div className="App bg-grey1">
      <div className="h-full bg-grey1">
        <HeaderComponent />
      </div>
      <div className="h-fit">
        <div className="legal-container poap-legal  font-onest text-white text-left">
          <>
            <p>
              <strong>CONDIZIONI PER L'ADESIONE ALL&rsquo;APPLICAZIONE</strong>
            </p>
            <p>(artt. 1341, 1342, 1382 e 1456 c.c., Reg. UE 2019/1150)</p>
            <p>
              Tra Onlytech Industries s.r.l. con sede in via Monte Grappa 6/L,
              Thiene (VI), P.IVA 04346880240 in persona
              dell&rsquo;amministratore delegato e legale rappresentante
              Antonino Abbate (d&rsquo;ora in poi anche &ldquo;Gestore&rdquo;) e
              l&rsquo;&ldquo;Aderente&rdquo;
            </p>
            <p>Premesso che:</p>
            <ol>
              <li>
                Il Gestore ha realizzato il l'applicazione popupevents.xyz per
                la vendita di Proof of attendance protocol (POAP )
              </li>
              <li>
                L'applicazione realizzata dal Gestore, tramite il collegamento a
                servizi terzi di Wallet e l&rsquo;incorporazione degli strumenti
                necessari per operare sulla blockchain polygon, permette di
                svolgere in modo semplificato e automatico le operazioni
                necessarie per la creazione ed il trasferimento di POAP .
              </li>
              <li aria-level="1">
                L&rsquo;Aderente &egrave; un professionista che intende
                realizzare e distribuire POAP per sancire la partecipazione agli
                eventi da lui gestiti o organizzati&nbsp;
              </li>
            </ol>
            <p>
              Tutto ci&ograve; premesso, si conviene e si stipula quanto segue:
            </p>
            <p>
              <strong>1. DEFINIZIONI</strong>
            </p>
            <p>
              1.1 - Nel presente contratto i seguenti termini avranno il
              significato riportato accanto:
            </p>
            <ul>
              <li aria-level="1">
                Professionista: la persona fisica o giuridica che agisce
                nell'esercizio della propria attivit&agrave; imprenditoriale,
                commerciale, artigianale o professionale, ovvero un suo
                intermediario;
              </li>
              <li aria-level="1">
                Consumatore o Utente: la persona fisica che agisce per scopi
                estranei all'attivit&agrave; imprenditoriale, commerciale,
                artigianale o professionale eventualmente svolta, che utilizza
                l'applicazione per reclamare i POAP
              </li>
              <li aria-level="1">
                Contenuto: l&rsquo;opera protetta dal diritto d&rsquo;autore
                della quale alcuni diritti e/o facolt&agrave; vengono
                incorporati in un POAP offerto gratuitamente tramite
                l'applicazione;
              </li>
              <li aria-level="1">
                POAP (Proof of attendance protocol): I POAP sono badge digitali
                che vengono assegnati ai partecipanti agli eventi. I POAP sono
                memorizzati sulla blockchain, il che li rende sicuri e a prova
                di manomissione. I POAP possono essere utilizzati per dimostrare
                la partecipazione a un evento e per sbloccare contenuti e premi
                esclusivi;
              </li>
              <li aria-level="1">
                Applicazione: L&rsquo;applicazione mobile che consente di
                creare, distribuire e reclamare POAP relativi ad eventi di varia
                natura, online o offline;
              </li>
              <li aria-level="1">
                Gestore: Il proprietario della Piattaforma che si occupa della
                relativa gestione;
              </li>
              <li aria-level="1">
                Servizio: Il Complesso dei servizi forniti dal Gestore
                attraverso l'applicazione.
              </li>
            </ul>
            <p>
              <strong>2. OGGETTO DEL CONTRATTO</strong>
            </p>
            <p>
              2.1&nbsp;Il presente accordo regolamenta i rapporti tra le parti
              relativi a:
              <br />
            </p>
            <ol>
              <li aria-level="2">
                l&rsquo;adesione all'applicazione da parte dell&rsquo;Aderente e
                le condizioni di utilizzo dello stesso e dei relativi servizi;
              </li>
              <li aria-level="2">
                i rapporti e le transazioni commerciali tra il Gestore e
                l'Aderente aventi ad oggetto i Prodotti offerti sul
                l'applicazione;
                <br />
              </li>
            </ol>
            <p>
              <strong>3.&nbsp;</strong>
              <strong>
                CONDIZIONI DI ADESIONE PER GLI ADERENTI&nbsp;
                <br />
                <br />
                3.1&nbsp;Possono aderire al l'applicazione esclusivamente
                Professionisti che:
                <br />
              </strong>
            </p>
            <ol>
              <li aria-level="1">
                Si identificano conformemente alle norme vigenti, anche in
                materia di antiriciclaggio;
              </li>
              <li aria-level="1">forniscono dati veritieri;</li>
              <li aria-level="1">
                agiscono quali Professionisti nel settore dei prodotti offerti
                nel l'applicazione;
              </li>
              <li aria-level="1">
                Conoscono la normativa in materia di diritto d&rsquo;autore;
              </li>
              <li aria-level="1">
                Conoscono gli obblighi di legge, anche fiscali, cui sono
                soggetti in caso di cessione o concessione in licenza di diritti
                d&rsquo;autore;
              </li>
              <li aria-level="1">
                Conoscono gli obblighi imposti dalla legge ai professionisti che
                operano con i consumatori;
              </li>
              <li aria-level="1">
                si impegnano ad operare secondo elevati standard di correttezza
                e professionalit&agrave;, nonch&eacute; a far fronte in modo
                tempestivo e diligente a tutti gli obblighi e agli impegni
                derivanti dai contratti stipulati nel l'applicazione;&nbsp;
              </li>
              <li aria-level="1">
                accettano le condizioni generali di vendita allegate al presente
                contratto e il relativo modello di licenza standard.
              </li>
            </ol>
            <p>
              3.2 Con la richiesta di adesione, l&rsquo;Aderente dichiara di
              possedere i requisiti, impegnandosi a mantenerli per tutta la
              durata dell&rsquo;adesione, e di impegnarsi a rispettare le
              condizioni richiesti dal presente contratto.
            </p>
            <p>
              <strong>
                4. RISPETTO DELLE CONDIZIONI DI ADESIONE E VERIFICHE&nbsp;
              </strong>
            </p>
            <p>
              4.1 - l'Aderente si impegna a rispettare integralmente le
              condizioni di adesione e alla richiesta di adesione dovr&agrave;
              identificarsi e compilare la modulistica fornita dal
              Gestore.&nbsp;
            </p>
            <p>
              4.2 &ndash; Il Gestore si riserva espressamente il diritto di
              procedere alla verifica del rispetto delle condizioni di adesione
              nonch&eacute; di effettuare approfondimenti, anche raccogliendo
              ulteriori informazioni presso l&rsquo;Aderente o terzi, prima di
              concedere l&rsquo;accesso all'applicazione, anche al fine di
              rispettare le normative cui potrebbe essere soggetto ( ad es. in
              materia di antiriciclaggio).&nbsp;
            </p>
            <p>
              4.3 - Ove le condizioni di adesione non siano rispettate
              dall'Aderente al momento della stipula del presente contratto o
              successivamente durante la relativa esecuzione, ovvero a seguito
              di verifiche emergessero dubbi sull&rsquo;identit&agrave;
              dell&rsquo;Aderente, sulla titolarit&agrave; dei diritti sui
              Contenuti inseriti nel l'applicazione o sulla veridicit&agrave;
              delle informazioni fornite, il Gestore si riserva il diritto
              insindacabile di rifiutare la richiesta di adesione o, qualora
              fosse stata gi&agrave; concessa, di escludere l&rsquo;Aderente
              dall'applicazione o risolvere il presente contratto con effetti
              immediati, salvo in ogni caso il diritto al risarcimento del danno
              a favore del Gestore.&nbsp;
            </p>
            <p>
              4.4 &ndash; In caso di mancato rispetto da parte dell'Aderente
              delle condizioni previste dal presente contratto, ed in
              particolare, di cui al precedente punto 3.1, lo stesso
              dovr&agrave; manlevare il Gestore e tenerlo indenne da qualsiasi
              danno e responsabilit&agrave;. Il Gestore si riserva di proporre
              ogni azione ritenuta opportuna nei confronti dell'Aderente.
              <br />
              <br />
              <strong>5.&nbsp;</strong>
              <strong>REQUISITI PER IL COLLEGAMENTO All'applicazione</strong>
            </p>
            <p>
              5.1 - Il collegamento all'applicazione avverr&agrave; attraverso
              la connessione Internet. Pertanto Per visualizzare ed interagire
              correttamente con l'applicazione &egrave; necessaria una
              connessione internet, ed un dispositivo in grado di accedere ed
              interagire con gli app store Google play e Apple store. Il
              rispetto di tali requisiti &egrave; a totale carico
              dell&rsquo;Aderente e ove mancassero il Gestore non
              garantir&agrave; l'accesso o il corretto funzionamento
              dell'applicazione e dei suoi servizi.&nbsp;
            </p>
            <p>
              5.2 &ndash; Per poter utilizzare l'applicazione l&rsquo;Aderente
              dovr&agrave; possedere il Wallet Web3auth e rispettare gli
              ulteriori requisiti tecnici indicati nelle FAQ del l'applicazione.
              Tali servizi sono realizzati e gestiti da terzi nonch&eacute;
              regolati dalle relative condizioni contrattuali. Il Gestore non ha
              alcun potere di controllo o verifica sugli stessi, pertanto non
              sar&agrave; responsabile per eventuali interruzioni, sospensioni,
              cessazioni e malfunzionamenti di tali servizi ovvero in caso di
              incidenti di sicurezza. Pertanto tali eventi non daranno diritto
              ad alcun risarcimento da parte del Gestore a favore
              dell&rsquo;Aderente, al quale rinuncia espressamente con la
              conclusione del presente contratto.{" "}
            </p>
            <p>
              <strong>
                6. REGISTRAZIONE E ACCESSO ALL'APPLICAZIONE
                <br />
                <br />
              </strong>
              6.1 - L&rsquo;accesso all'applicazione avverr&agrave; previa
              identificazione, registrazione e accettazione del presente
              contratto con i relativi allegati (Termini d&rsquo;uso
              dell&rsquo;applicazione) che lo compongono nonch&eacute; delle
              Policy e Linee guida presenti sull'applicazione. Con la
              registrazione l&rsquo;Aderente dovr&agrave; scegliere delle
              credenziali di autenticazione che rispettino i requisiti indicati
              nel form di inserimento.
            </p>
            <p>
              6.2 - Successivamente alla registrazione, l&rsquo;accesso
              avverr&agrave; tramite l&rsquo;utilizzo delle credenziali di
              autenticazione che l&rsquo;Aderente si impegna a custodire
              garantendone la riservatezza. La custodia delle credenziali
              &egrave; sotto l&rsquo;esclusiva responsabilit&agrave;
              dell&rsquo;Aderente non avendone copia il Gestore. Pertanto
              l&rsquo;eventuale accesso da parte di terzi a tali credenziali o
              la loro compromissione non daranno diritto ad alcun risarcimento
              da parte del Gestore a favore dell&rsquo;Aderente, al quale
              rinuncia espressamente con la conclusione del presente contratto.
            </p>
            <p>
              In caso di necessit&agrave;, il Gestore si riserva di chiedere
              allo stesso la modifica delle credenziali ed ulteriori azioni per
              mantenere la sicurezza dell'applicazione e dell&rsquo;account che
              l&rsquo;Aderente si obbliga a rispettare prontamente.
              <br />
              <br />
              <strong>
                7. SERVIZI RESI AGLI ADERENTI
                <br />
                <br />
              </strong>
            </p>
            <p>
              7.1 -servizi resi dal Gestore agli Aderenti mediante
              l'applicazione sono:&nbsp;
            </p>
            <ul>
              <li>
                a) messa a disposizione di uno spazio per realizzare pagine
                vetrina e per inserire i POAP relativi agli eventi
              </li>
              <li>
                b) la gestione della procedura di distribuzione e claim dei POAP
              </li>
            </ul>
            <p>
              7.2 - La presenza sull'applicazione non fornisce
              all&rsquo;Aderente alcuna posizione di esclusiva merceologica,
              territoriale o di altro tipo n&eacute; situazioni di vantaggio
              rispetto ad altri potenziali aderenti.&nbsp;
            </p>
            <ol start="8">
              <li>
                <strong>
                  {" "}
                  CORRISPETTIVI PER I SERVIZI FORNITI DAL GESTORE
                </strong>
              </li>
            </ol>
            <p>
              8.1 &ndash; Il costo dei servizi resi dal Gestore &egrave; pari al
              contratto pattuito con l&rsquo;aderente. Il pagamento di tale
              costo avverr&agrave; tramite le modalit&agrave; pattuite nel
              contratto.
            </p>
            <ol start="9">
              <li>
                <strong> OBBLIGHI DELL'ADERENTE</strong>
              </li>
            </ol>
            <p>
              9.1 - L'Aderente si impegna a rispettare gli obblighi derivanti
              dal presente contratto e dalla normativa applicabile allo stesso e
              all&rsquo;attivit&agrave; svolta tramite il l'applicazione, anche
              fiscali e di protezione dati personali, nonch&eacute; a far
              rispettare gli stessi ai propri dirigenti, collaboratori,
              dipendenti, rappresentanti e a chiunque svolga, a qualsiasi
              titolo, eventuali attivit&agrave; connesse con il presente
              contratto a favore dell&rsquo;Aderente.&nbsp;
            </p>
            <p>
              9.2 - L'Aderente si assume ogni pi&ugrave; ampia
              responsabilit&agrave; sulle informazioni forniti al Gestore o
              pubblicati sul l'applicazione nonch&eacute; sui contenuti offerti
              gratuitamente nell&rsquo;applicazione, di cui ne garantisce la
              piena titolarit&agrave; e la disponibilit&agrave; dei diritti dei
              contenuti inseriti nei POAP non essendo sottoposti a vincoli
              contrattuali che impediscano tale attivit&agrave;. Pertanto, il
              Gestore non sar&agrave; in alcun caso responsabile per
              informazioni, materiali e Contenuti inseriti nell'applicazione
              dall&rsquo;Aderente, riservandosi, in ogni caso, la facolt&agrave;
              di rimuoverli qualora violassero il presente contratto, le Policy
              e le Linee guida del l'applicazione. Nel pubblicare
              l&rsquo;annuncio di Vendita di un Prodotto, l&rsquo;Aderente si
              obbliga a rispettare le Linee guida vigenti dell'applicazione in
              materia, rispettando in particolare, quanto previsto per la
              puntuale Descrizione del Prodotto.
            </p>
            <p>9.3 - L'Aderente si obbliga a:&nbsp;</p>
            <ol>
              <li>
                a) non immettere sul l'applicazione, alcun materiale che violi
                norme di legge, diritti di terzi (come ad es. diritti d'autore o
                altri diritti di propriet&agrave; intellettuale o industriale) o
                le Linee guida del l'applicazione, ovvero ancora che possa
                risultare lesivo per gli Utenti. Il Gestore si riserva di
                fornire Linee guida specifiche sui materiali e Contenuti
                consentiti nel l'applicazione che l&rsquo;Aderente si obbliga a
                rispettare e, in caso di violazione o segnalazione da parte di
                un Utente, il Gestore si riserva il diritto di rimuovere tali
                contenuti senza preavviso e senza che l&rsquo;Aderente possa
                contestare alcunch&eacute; al Gestore o chiedere, a qualsiasi
                titolo, un risarcimento del danno. Tale facolt&agrave;
                potr&agrave; essere esercitata dal Gestore anche qualora il
                Prodotto sia stato gi&agrave; distribuito, rimuovendone ogni
                traccia dall'applicazione senza che ci&ograve; travolga la
                validit&agrave; della distribuzione effettuata al quale rapporto
                il Gestore &egrave; estraneo.
              </li>
              <li>
                b) non servirsi, consentire o dar modo ad altri di servirsi
                dell'applicazione per atti contro l'ordine pubblico o con lo
                scopo di molestare o danneggiare terzi e, comunque, a non fare
                uso del l'applicazione in modo da commettere o favorire la
                commissione di atti illeciti di qualsivoglia tipo;&nbsp;
              </li>
              <li>
                c) non immettere sull'applicazione, o fornire al Gestore,
                informazioni false, diffamatorie, o ingannevoli nei confronti di
                altri aderenti al l'applicazione, utenti o comunque terzi;&nbsp;
              </li>
              <li>
                d) utilizzare l'applicazione ed i relativi servizi,
                nonch&eacute; concludere contratti di compravendita dei
                Prodotti, in modo corretto senza violare alcuna norma di legge o
                regolamento;&nbsp;
              </li>
            </ol>
            <p>
              9.4 - &Egrave; onere dell&rsquo;Aderente disporre a proprie spese
              di sistemi che gli permettano di operare sull'applicazione
              rispettando la configurazione minima di cui all&rsquo;art.
              5.&nbsp;
            </p>
            <p>
              9.5 - &Egrave; fatto divieto all&rsquo;Aderente di cedere a terzi
              in qualsiasi modo e sotto qualsiasi forma, anche parzialmente, il
              presente contratto senza il consenso scritto del Gestore.
            </p>
            <p>
              9.6 - L'Aderente si obbliga a manlevare e a tenere indenne il
              Gestore da qualsivoglia perdita, danno, responsabilit&agrave;,
              costo o spesa, incluse le spese legali e danni di immagine,
              derivanti da ogni compiuta violazione delle disposizioni di cui al
              presente contratto.
            </p>
            <ol start="10">
              <li>
                <strong> PROPRIETA' INDUSTRIALE E INTELLETTUALE</strong>
              </li>
            </ol>
            <p>
              10.1 &ndash; Il Gestore &egrave; l&rsquo;esclusivo titolare del
              marchio popupevents, del domain name popupevents.xyz e del sito
              internet omonimo, nonch&eacute; di ogni logo, immagine e segno
              distintivo utilizzato.&nbsp;
            </p>
            <p>
              10.2 - L'Aderente non acquisisce alcun diritto rispetto a
              programmi informatici, banche dati, informazioni, notizie ed altri
              dati, immagini, loghi, marchi e ogni altro segno distintivo e/o
              diritto di propriet&agrave; industriale e/o intellettuale
              utilizzati dal Gestore o da terzi nel l'applicazione che si
              impegna a non violare. L&rsquo;Aderente si obbliga altres&igrave;
              a non cedere, dare in licenza o sublicenza gli stessi e/o i beni
              che ne sono oggetto, n&eacute; consentirne l'uso da parte di
              soggetti terzi in qualsivoglia altro modo e sia a titolo gratuito
              che a titolo oneroso.&nbsp;
            </p>
            <p>
              10.3 - L'Aderente manleva il Gestore da qualsiasi
              responsabilit&agrave;, obbligandosi a risarcirlo degli eventuali
              danni e/o costi, incluse le spese legali e danni di immagine, nel
              caso di richieste avanzate nei suoi confronti da terzi (o da altri
              aderenti) fondate sulla violazione da parte dell'Aderente di
              diritti di terzi (a titolo meramente esemplificativo: segno
              distintivi, diritti di propriet&agrave; industriale e/o
              intellettuale ecc.).&nbsp;
            </p>
            <ol start="11">
              <li>
                <strong> PUBBLICITA' SULL&rsquo;APPLICAZIONE</strong>
              </li>
            </ol>
            <p>
              11.1 &ndash; il Gestore si riserva, a suo insindacabile giudizio,
              il diritto di inserire sull'applicazione avvisi pubblicitari
              compatibili con l'attivit&agrave; svolta, nonch&eacute;
              collegamenti a siti e portali di terzi.&nbsp;
            </p>
            <ol start="12">
              <li>
                <strong> DURATA DELL'ADESIONE</strong>
              </li>
            </ol>
            <p>12.1 - L'adesione al l'applicazione ha durata fino a:</p>
            <ul>
              <li aria-level="1">
                Il recesso da parte dell&rsquo;Aderente che potr&agrave;
                avvenire liberamente mediante la cancellazione del profilo;
              </li>
              <li aria-level="1">
                la risoluzione del presente accordo per inadempimento;
              </li>
              <li aria-level="1">
                dall&rsquo;esclusione dell&rsquo;Aderente ad opera del Gestore
                in conformit&agrave; al presente contratto;
              </li>
              <li aria-level="1">
                la cessazione del servizio fornito dal Gestore tramite
                l'applicazione.
              </li>
            </ul>
            <p>
              12.2 &ndash; La cessazione dell&rsquo;adesione dell&rsquo;Aderente
              non travolger&agrave; i contratti relativi ai Prodotti gi&agrave;
              distribuiti che rimarranno presenti all&rsquo;interno
              dell'applicazione con la relativa descrizione.
            </p>
            <ol start="13">
              <li>
                <strong>
                  {" "}
                  MODIFICA O SOSPENSIONE DEI SERVIZI E CESSIONE DEL CONTRATTO
                </strong>
              </li>
            </ol>
            <p>
              13.1 - Il Gestore si riserva il diritto di modificare, integrare o
              ridurre i servizi offerti, nonch&eacute; di sospendere o cessare
              l&rsquo;attivit&agrave; del l'applicazione, temporaneamente o a
              titolo definitivo, senza che ci&ograve; possa comportare il
              diritto ad alcun risarcimento a favore dell&rsquo;Aderente al
              quale rinuncia espressamente con la conclusione del presente
              contratto&nbsp;
            </p>
            <p>
              13.2 - l&rsquo;esecuzione di interventi sul l'applicazione o di
              operazioni di manutenzione che ne impediscano l&rsquo;utilizzo
              sar&agrave;, ove possibile, tempestivamente comunicata dal Gestore
              all'Aderente, salvo il caso in cui si rendano necessari interventi
              che, per la loro particolare urgenza, non consentano di dare il
              menzionato preavviso. La sospensione del servizio per
              attivit&agrave; di manutenzione ed implementazione
              dell'applicazione non dar&agrave; diritto ad alcun risarcimento a
              favore dell&rsquo;Aderente al quale rinuncia espressamente con la
              conclusione del presente contratto.
            </p>
            <p>
              13.3 - Il Gestore si riserva espressamente la facolt&agrave; di
              cedere, a titolo oneroso o gratuito, locare, in tutto o in parte,
              l'applicazione, ed i contratti ad esso collegati tra cui il
              presente, a soggetti terzi, ovvero concedere agli stessi
              qualsivoglia diritto connesso all'applicazione medesimo. Nel caso
              in cui il Gestore intenda, a suo insindacabile giudizio, avvalersi
              di tale facolt&agrave;, ne dar&agrave; notizia all&rsquo;Aderente
              mediante comunicazione tramite l'applicazione.&nbsp;
            </p>
            <ol start="14">
              <li>
                <strong> . CLAUSOLA RISOLUTIVA ESPRESSA</strong>
              </li>
            </ol>
            <p>
              14.1 - Fatto salvo l'ulteriore risarcimento del danno, il presente
              Contratto si intender&agrave; risolto di diritto ai sensi
              dell'art. 1456 c.c. nel caso in cui l'Aderente violi anche una
              soltanto delle obbligazioni di cui agli Articoli:&nbsp;
            </p>
            <p>- 3 (Condizioni di adesione per gli Aderenti);</p>
            <p>- 4 (Rispetto delle condizioni di adesione e verifiche);</p>
            <p>- 9 (Obblighi dell&rsquo;Aderente)</p>
            <p>- 10 (Obblighi di correttezza degli Aderenti);</p>
            <p>- 12 (Propriet&agrave; industriale ed intellettuale);</p>
            <p>- 18 (Tutela dell'immagine del Gestore);</p>
            <p>
              - 19 (Obblighi di riservatezza).
              <br />
              <br />
            </p>
            <p>
              14.2 - Al verificarsi di uno qualsiasi degli eventi di cui al
              precedente punto&nbsp;
            </p>
            <p>
              14.3, la risoluzione del contratto operer&agrave; di diritto dal
              momento del ricevimento da parte dell'Aderente di una
              comunicazione con raccomandata a/r o PEC contenente la
              contestazione dell'inadempimento e l'intenzione del Gestore di
              avvalersi della presente clausola risolutiva espressa.&nbsp;
            </p>
            <p>
              14.4 - Nell'ipotesi di violazione da parte dell'Aderente delle
              norme stabilite nel presente contratto, il Gestore potr&agrave;, a
              suo insindacabile giudizio, sospendere l'accesso al l'applicazione
              da parte dell'Aderente per il tempo che riterr&agrave; opportuno,
              e/o risolvere il presente contratto, senza essere tenuto ad alcun
              indennizzo o risarcimento fatto salvo ogni diritto al risarcimento
              dei danni a favore del Gestore.&nbsp;
            </p>
            <ol start="15">
              <li>
                <strong> ESONERO DI RESPONSABILITA' PER IL GESTORE</strong>
              </li>
            </ol>
            <p>
              15.1 &ndash; Il Gestore non sar&agrave; responsabile per danni
              diretti o indiretti, incidentali o consequenziali, quali, a mero
              titolo esemplificativo e non esaustivo, il mancato guadagno,
              perdite di business e/o di opportunit&agrave; commerciali e tutto
              quanto possa derivare da:
            </p>
            <ol>
              <li aria-level="1">
                Il rifiuto della richiesta di adesione da parte
                dell&rsquo;Aderente o l&rsquo;esclusione dopo l&rsquo;adesione;
              </li>
              <li aria-level="1">
                dati immessi sull'applicazione o forniti dall'Aderente e/o dagli
                altri aderenti anche qualora contengano errori, omissioni ed
                inesattezze o non siano veritieri.
              </li>
              <li aria-level="1">
                dalla gestione del l'applicazione e dai Servizi forniti, nel
                caso di errori, omissioni ed inesattezze nei dati trasmessi
                neppure in caso di interruzioni, sospensioni, ritardi e anomalie
                nella gestione del l'applicazione e nella erogazione dei
                Servizi, anche se derivanti da problemi tecnici o interventi
                manutentivi ed implementativi dei sistemi, salvo che non siano
                direttamente e immediatamente ricollegabili ad un comportamento
                doloso del Gestore.&nbsp;
              </li>
              <li aria-level="1">
                incidenti di sicurezza, intrusioni informatiche o altra
                illegittima intromissione o utilizzo di dati da parte di terzi
                non autorizzati, anche a seguito di compromissione delle
                credenziali di autenticazione dell&rsquo;Aderente, nonch&eacute;
                da truffe commerciali o altri comportamenti scorretti o
                illegittimi tenuti dalle imprese aderenti o terzi, salvo che non
                siano direttamente e immediatamente ricollegabili ad un
                comportamento doloso del Gestore.&nbsp;
              </li>
              <li aria-level="1">
                malfunzionamenti dell'applicazione, o mancata
                disponibilit&agrave; dello stesso e dei relativi servizi anche
                se direttamente o indirettamente riconducibili a condotte o
                omissioni dell'hosting provider o del Gestore salvo che non
                siano direttamente e immediatamente ricollegabili ad un
                comportamento doloso di quest&rsquo;ultimo.
              </li>
              <li aria-level="1">
                della mancata disponibilit&agrave; di servizi e dispositivi
                necessari per l&rsquo;accesso all'applicazione come indicati
                nell&rsquo;art. 5 e nelle FAQ dell'applicazione anche in caso di
                interruzioni, sospensioni, cessazioni e malfunzionamenti degli
                stessi o incidenti di sicurezza.
              </li>
              <li aria-level="1">
                Modifica, integrazione o riduzione dei servizi offerti,
                sospensione dell&rsquo;attivit&agrave; dell'applicazione o
                relativa cessazione temporanea o definitiva, nonch&eacute;
                cessione della propriet&agrave; o della gestione
                dell'applicazione a terzi.
              </li>
              <li aria-level="1">
                Rimozione dall'applicazione di materiale, Contenuti, Prodotti
                dell&rsquo;Aderente che violi norme di legge, diritti di terzi
                (come ad es. diritti d'autore o altri diritti di
                propriet&agrave; intellettuale o industriale) o le Linee guida
                del l'applicazione, ovvero ancora che possa risultare lesivo per
                gli utenti.
              </li>
            </ol>
            <p>
              15.2 - Ove insorgano controversie tra gli aderenti, e in
              conseguenza di ci&ograve; vengano intraprese azioni legali nei
              confronti del Gestore , l'Aderente si obbliga a risarcire e
              manlevare il Gestore di qualsiasi danno subito a causa di tali
              azioni ove le stesse siano dovute e connesse, in modo diretto o
              indiretto, ad azioni od omissioni dell&rsquo;Aderente stesso,
              procedendo al ristoro di tutti i costi e delle spese sostenute,
              comprese quelle legali.&nbsp;
            </p>
            <p>
              15.3 - In nessun caso il Gestore sar&agrave; ritenuto responsabile
              in ordine all'adempimento delle obbligazioni scaturenti dai
              contratti conclusi dall'Aderente con altri aderenti, n&eacute; di
              quelle derivanti dagli eventuali contratti conclusi dall'Aderente
              con Acquirenti e soggetti terzi in relazione alla fornitura dei
              Servizi offerti sul l'applicazione.&nbsp;
            </p>
            <ol start="16">
              <li>
                <strong> TUTELA DELL'IMMAGINE DEL GESTORE&nbsp;</strong>
              </li>
            </ol>
            <p>
              16.1 - L'Aderente, fatti salvi gli altri obblighi posti a suo
              carico dal presente Contratto, si impegna espressamente ad operare
              sull'applicazione rispettando i pi&ugrave; elevati standard di
              correttezza e professionalit&agrave; e, comunque, ad astenersi da
              qualsiasi azione, iniziativa o comportamento che possa essere o
              divenire sconveniente o pregiudizievole per l'immagine o la
              reputazione del Gestore, o che possa comunque arrecare discredito
              o pregiudizio allo stesso;&nbsp;
            </p>
            <ol start="17">
              <li>
                <strong> OBBLIGHI DI RISERVATEZZA&nbsp;</strong>
              </li>
            </ol>
            <p>
              17.1 - Le Parti si impegnano a rispettare e a far rispettare
              l'obbligo di segretezza, relativamente a tutte le informazioni, i
              dati, le documentazioni e le notizie, in qualunque forma fornite,
              che non siano di pubblico dominio fino a quando non lo diventino
              per fatto non imputabile alla violazione di un obbligo di
              riservatezza.&nbsp;
            </p>
            <p>
              17.2 - Le Parti si danno reciprocamente atto che le informazioni
              considerate riservate e i dati personali di terze persone fisiche
              vengono trattati osservando misure di sicurezza idonee a garantire
              la riservatezza e la confidenzialit&agrave; dei dati stessi.&nbsp;
            </p>
            <ol start="18">
              <li>
                <strong> OBBLIGHI DI SICUREZZA</strong>
              </li>
            </ol>
            <p>
              18.1 - L'Aderente si obbliga a rispettare in materia di sicurezza
              tutte le indicazioni che il Gestore ha stabilito e che
              comunicher&agrave; anche in futuro. L'Aderente, inoltre, si
              impegna a comunicare tempestivamente al Gestore ogni anomalia che
              lo stesso riscontri nell'utilizzo dell'applicazione e dei Servizi
              qualora abbia il fondato sospetto che ci&ograve; derivi da errori,
              malfunzionamenti dell'applicazione o da un tentativo di accesso
              non autorizzato, di intrusione, di manomissione o di altra
              attivit&agrave; illegittima posta in essere da terzi soggetti o da
              altre imprese aderenti.&nbsp;
            </p>
            <ol start="19">
              <li>
                <strong> COMUNICAZIONI</strong>
              </li>
            </ol>
            <p>
              19.1 - Le comunicazioni tra il Gestore e l'Aderente avranno luogo
              preferibilmente per il tramite di messaggi e-mail o PEC ai
              rispettivi indirizzi elettronici (indicati in epigrafe per il
              Gestore e nel form di registrazione per l'Aderente) ovvero tramite
              raccomandata A/R da inviare presso le rispettive sedi.&nbsp;
            </p>
            <p>
              19.2 - Le Parti si obbligano a comunicare, nel corso della vigenza
              del presente contratto, ogni variazione dei rispettivi indirizzi.
              In caso di mancata comunicazione della variazione, tutte le
              comunicazioni che saranno effettuate in base ai vecchi recapiti
              avranno piena efficacia e validit&agrave; e si considereranno
              conosciute una volta arrivate a tali indirizzi.&nbsp;
            </p>
            <ol start="20">
              <li>
                <strong> ALLEGATI</strong>
              </li>
            </ol>
            <p>
              20.1 - Costituiscono parte integrante del presente contratto i
              seguenti Allegati:&nbsp;
            </p>
            <p>
              "Termini d&rsquo;uso della applicazione". Informativa aderenti,
              Policy e Linee guida pubblicate dal Gestore nell'applicazione.
            </p>
            <ol start="21">
              <li>
                <strong>
                  {" "}
                  LEGGE APPLICABILE E RISOLUZIONE DELLE CONTROVERSIE
                </strong>
              </li>
            </ol>
            <p>
              21.1 &ndash; Legge applicabile e Foro competente. Il presente
              Accordo, come i diritti e le obbligazioni nascenti dallo stesso,
              sono regolati dalla legge Italiana.
            </p>
            <p>
              21.2 &ndash; Ogni vertenza, nessuna esclusa, che dovesse insorgere
              tra le Parti (a mero titolo esemplificativo e non esaustivo
              validit&agrave;, nullit&agrave;, annullabilit&agrave; o
              inefficacia di qualsiasi tipo, sia totale che parziale,
              interpretazione, risoluzione, applicazione ed esecuzione,
              inadempimento ecc.) ed avente ad oggetto il presente accordo ed i
              diritti ed obblighi nascenti dallo stesso sar&agrave; risolta
              secondo la seguente procedura che le Parti si obbligano a seguire:
            </p>
            <ol>
              <li>
                {" "}
                Mediazione. Le Parti cercheranno di risolvere la controversia
                tramite una procedura di mediazione che avvieranno
                congiuntamente, o ad opera della Parte pi&ugrave; diligente
                ovvero interessata, presso l&rsquo;organismo Resolutia sede di
                Vicenza o dell&rsquo;Organismo di Mediazione Forense di Vicenza
                a scelta dell&rsquo;istante secondo i relativi statuti e
                regolamenti. La presente clausola non trover&agrave;
                applicazione qualora la controversia rientri tra quelle
                sottoposte a negoziazione assistita da uno o pi&ugrave; avvocati
                prevista dal capo II del d.l. n. 132/2014 ovvero a mediazione
                obbligatoria ai sensi del d.lgs. n. 28 del 2010 e tali organismi
                risultino non competenti territorialmente. In tali casi, al
                posto di quanto qui previsto, le parti dovranno esperire la
                procedura obbligatoria secondo le relative norme. Oltre a tali
                eccezioni, la presente clausola potr&agrave; essere derogata
                dalle Parti qualora:
              </li>
              <li>
                {" "}
                non fosse possibile, per ragioni che esulano dalla
                volont&agrave; delle stesse, esperire la procedura di mediazione
                presso tali organismi, potendo in tal caso le Parti, o quella
                tra loro pi&ugrave; diligente, rivolgersi ad altro organismo di
                Mediazione;
              </li>
              <li>
                {" "}
                una parte intenda avviare un procedimento per ingiunzione o di
                Accertamento tecnico preventivo sussistendone i requisiti.&nbsp;
              </li>
              <li>
                {" "}
                Foro competente. In caso di mancato esito positivo del
                procedimento di cui al punto precedente, e salvo i casi di fori
                competenti in via esclusiva per legge, per la definizione della
                controversia sar&agrave; competente in via esclusiva il
                Tribunale di Vicenza ovvero, se competente, la sezione
                specializzata in materia di imprese del Tribunale di Venezia.
              </li>
              <li>
                <strong> CLAUSOLE FINALI</strong>
              </li>
            </ol>
            <p>
              22.1 - Il presente contratto, incluse le Premesse e gli Allegati
              che ne formano parte inscindibile e sostanziale, contiene
              integralmente tutte le pattuizioni intervenute tra le Parti e
              sostituisce qualsiasi precedente accordo orale e/o scritto.&nbsp;
            </p>
            <p>
              22.2 - L'invalidit&agrave; e/o inefficacia, anche sopravvenuta/e,
              di una o pi&ugrave; clausole non comporter&agrave;
              l'invalidit&agrave;, l'inefficacia o lo scioglimento del presente
              contratto.&nbsp;
            </p>
            <p>
              22.3 &ndash; Il Gestore si riserva di modificare i termini del
              presente accordo, i relativi allegati, nonch&eacute; le Policy e
              le Linee guida presenti nel l'applicazione con preavviso
              comunicato all&rsquo;Aderente o pubblicato nel l'applicazione di
              15 giorni, decorsi i quali, anche in assenza di formale
              accettazione, le modifiche diverranno efficaci e il prosieguo
              nell&rsquo;utilizzo della piattaforma varr&agrave; come
              accettazione delle modifiche da parte dell&rsquo;Aderente. Il
              Gestore si riserva comunque il diritto di sospendere
              l&rsquo;accesso dell&rsquo;Aderente dall'applicazione, o comunque
              la relativa fruizione, in assenza di formale accettazione delle
              modifiche contrattuali qualora fosse richiesta all&rsquo;Aderente.
            </p>
            <p>
              22.4 - L'acquirente e/o il fornitore rinunciano espressamente al
              diritto di contestare la validit&agrave; del presente contratto e
              di tutti i futuri contratti stipulati on line o attraverso lo
              scambio di e-mail adducendo quale unico motivo lo strumento
              informatico e/o telematico.&nbsp;
            </p>
            <p>
              22.5 - Il presente contratto rimane valido ed efficace anche nel
              caso in cui il Gestore decida di alienare a titolo oneroso o
              gratuito, affittare o locare il l'applicazione ai sensi dell'art.
              15 a soggetti terzi, ovvero concedere agli stessi qualsiasi
              diritto avente ad oggetto il l'applicazione medesimo.&nbsp;
            </p>
            <p>
              Ai sensi e per gli effetti degli artt. 1341 e 1342 c.c. ,
              l'Aderente dichiara di aver attentamente letto il contratto e di
              approvare espressamente gli articoli e/o le clausole qui di
              seguito indicate:&nbsp;
            </p>
            <p>- Art. 4 (rispetto delle condizioni di adesione e verifiche);</p>
            <p>- Art. 5 (Requisiti per il collegamento al l'applicazione);</p>
            <p>- Art. 6 (Registrazione al l'applicazione);</p>
            <p>- Art. 9 (Obblighi dell'Aderente);</p>
            <p>- Art. 10 (obblighi di correttezza degli Aderenti);</p>
            <p>- Art. 15 (Cessione del contratto);</p>
            <p>- Art. 16 (Clausola risolutiva espressa);</p>
            <p>- Art. 17 (Esonero di responsabilit&agrave; dell'Gestore);</p>
            <p>- Art. 19 (Responsabilit&agrave; dell'Aderente);</p>
            <p>- Art. 21 (Comunicazioni)</p>
            <p>
              - Art. 23 (legge applicabile e risoluzione delle controversie);
            </p>
            <p>- Art. 24 (Clausole finali).</p>
          </>
        </div>
      </div>
      <div className=" w-full bg-grey">
        {/*footer */}
        <FooterComponent />
      </div>
    </div>
  );
}
export default CreatorUsage;
