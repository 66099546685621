import logo from "../logo.svg";
import "../App.scss";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
//Style
import "tailwindcss/tailwind.css";
import "../index.css";

//components
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
function General() {
  return (
    <div className="App bg-grey1">
      <div className="h-full bg-grey1">
        <HeaderComponent />
      </div>
      <div className="h-30">
        <div className="legal-container poap-legal  font-onest text-white text-left">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam
          magna, vestibulum ultrices varius nec, facilisis eget nunc. Quisque
          porta, arcu id eleifend lobortis, purus lacus iaculis ante, nec
          laoreet quam lorem vel justo. Nunc malesuada sapien a metus
          sollicitudin, id aliquam nulla pellentesque. Nam laoreet iaculis
          magna, et scelerisque risus convallis id. Duis imperdiet rhoncus ex,
          et tristique urna rutrum vel. Nunc eu lectus convallis, consequat nibh
          sed, vulputate eros. Donec sed dignissim purus. In bibendum urna vitae
          ligula dignissim, at pretium diam lobortis. Integer interdum lorem eu
          tincidunt luctus. Aliquam fringilla justo metus, vel congue ante
          rutrum a. Pellentesque id augue at nisi finibus ullamcorper. Nullam
          sed porta libero. In condimentum eu nisl ac sagittis. Sed ut arcu at
          ex rutrum efficitur. Fusce molestie massa a malesuada convallis. Sed
          tristique felis a elementum feugiat. Morbi consectetur congue orci sit
          amet euismod. Pellentesque euismod sem elit, et vulputate sem maximus
          et. Vestibulum id vulputate ex. Donec ac tellus velit. Vestibulum ante
          ipsum primis in faucibus orci luctus et ultrices posuere cubilia
          curae; Proin sapien neque, egestas a ligula quis, gravida dictum ex.
          Class aptent taciti sociosqu ad litora torquent per conubia nostra,
          per inceptos himenaeos. Donec non mollis enim. Aenean elementum semper
          odio in sodales. Etiam varius bibendum dictum. Phasellus luctus tellus
          a lobortis dapibus. Sed malesuada non felis vel malesuada. Vestibulum
          nec ligula a elit pretium ullamcorper. Cras non euismod massa. Mauris
          eget risus facilisis, tempus arcu vel, dapibus ex. Morbi eget
          vulputate ipsum. Etiam non eleifend libero. Mauris egestas massa
          lectus, ac tincidunt risus mattis sit amet. Nullam blandit tellus
          massa. Nullam et suscipit purus, in porttitor velit. Donec eget nisi
          vestibulum, eleifend dui vel, sagittis nulla. Aliquam erat volutpat.
          Nullam semper arcu nec sem eleifend feugiat. Donec et scelerisque
          quam. Nullam lectus risus, viverra quis urna et, ullamcorper dictum
          dui. Nunc rutrum ante nisi, vel elementum nisl bibendum vitae. Donec
          at nisi sit amet magna porttitor aliquam eget nec tortor. Vestibulum
          accumsan orci vitae ligula posuere, sed tincidunt tellus vestibulum.
          Cras id placerat dui. Pellentesque est leo, feugiat nec purus eget,
          congue ornare sem. Phasellus hendrerit massa magna, eu dapibus felis
          ornare a. Nulla pulvinar varius metus, non tincidunt purus mollis id.
          Praesent non augue nibh. Nunc a molestie ligula. Sed vitae nunc quis
          ex suscipit auctor a nec nisl. Suspendisse eros lacus, vestibulum ac
          viverra eu, imperdiet at massa.
        </div>
      </div>
      <div className=" w-full bg-grey">
        {/*footer */}
        <FooterComponent />
      </div>
    </div>
  );
}
export default General;
