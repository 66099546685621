import React from "react";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

import { useNavigate } from "react-router-dom";

export const initializeGA = () => {
  ReactGA.initialize("KRVXDY3C99");
};

export const initializeFP = () => {
  ReactPixel.init("596085255522240");
};

const WithTrackers = (props) => {
  let history = useNavigate();
  history.listen((location) => {
    ReactGA.send({ hitType: "pageView", page: location.pathname });
    ReactPixel.pageView();
  });
  return <></>;
};

export default WithTrackers;
