import ".././App.scss";
import React, { useEffect, useRef } from "react";
//Style
import "tailwindcss/tailwind.css";
import ".././index.css";
import logo from "../static/ICONA-POAP-header.png";
import { useTranslation } from "react-i18next";
const HeaderComponent = (props) => {
  const [t, i18n] = useTranslation("common");
  return (
    <header
      className="border-maindark fixed z-50 w-full text-white"
      style={{ boxShadow: "0 0 50px rgba(0,0,0,0.15)" }}
    >
      <div className="flex flex-row justify-between w-full bg-main px-2 md:px-3">
        <div className="flex w-full py-2" style={{ alignItems: "center" }}>
          <div className="align-center flex " style={{ alignItems: "center" }}>
            <div className="omx-header-logo mr-4">
              <div className="flex flex-row justify-between">
                <a href="/">
                  <img className="w-6 my-auto" src={logo} />
                </a>
                <span className="pl-2 lg:pl-5 text-white my-auto font-flytrap">
                  POP-UP Events
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="my-auto flex flex-row space-x-3 text-white font-onest font-medium">
          <button
            className="hover:opacity-80"
            onClick={() => i18n.changeLanguage("it")}
          >
            Italiano
          </button>
          <button
            className="hover:opacity-80"
            onClick={() => i18n.changeLanguage("en")}
          >
            English
          </button>
        </div>
      </div>
    </header>
  );
};
export default HeaderComponent;
