import ".././App.scss";
import React, { useEffect, useRef, useState, useTransition } from "react";
//Style
import "tailwindcss/tailwind.css";
import ".././index.css";
//images
import discord from "../static/ICONA-DISCORD.png";
import ig from "../static/ICONA-IG.png";
import x from "../static/ICONA-X.png";
import logo from "../static/poapuplogo.png";
import { useTranslation } from "react-i18next";
const FooterComponent = (props) => {
  const { t } = useTranslation("common");

  return (
    <div className="w-full bg-grey rounded-tl-xl rounded-tr-xl">
      <footer className="w-full flex flex-col space-y-4 bg-grey py-3 lg:py-6 text-center font-serif text-xs text-black lg:pl-5 md:text-sm lg:text-left xl:text-base">
        <div className="flex lg:ml-presslogos">
          {/* row 1 */}
          <div className="hidden lg:block">
            <img
              className="lg:w-[335px] xl:w-[338px] xxl:w-[296px] "
              src={logo}
              alt="Logo"
            ></img>
          </div>
          {/* row 2 */}
          <div className="flex lg:ml-presslogos flex-col w-full ">
            <div className=" flex flex-col lg:flex-row mx-auto lg:mx-1 justify-between md:w-[80%] xl:w-4/6 xxl:w-1/2">
              <div className="flex flex-col ">
                <span className="text-white font-normal pb-1 md:pb-3 font-flytrap">
                  {t("footer.legal")}
                </span>
                <a
                  href="/legal/privacy-policy"
                  className="text-white font-onest font-medium "
                >
                  {t("footer.privacyPolicy")}
                </a>

                <a
                  href="/legal/buyer-terms-of-use"
                  className="text-white font-onest font-medium"
                >
                  {t("footer.termsOfUse")}
                </a>
                <a
                  href="/legal/creator-usage-application"
                  className="text-white font-onest font-medium"
                >
                  {t("footer.termsOfMembership")}
                </a>
              </div>
              <div className="flex flex-col pt-3 lg:pt-0">
                <span className="text-white font-normal font-flytrap pb-1 md:pb-3">
                  Social{" "}
                </span>
                <div className="flex flex-row space-x-4 mx-auto justify-arround">
                  <a href="https://discord.gg/JWD224S7sQ">
                    <img className="w-7 flex" src={discord}></img>
                  </a>
                  <a href="https://www.instagram.com/onlymusix_nft/">
                    <img className="w-7 flex" src={ig}></img>
                  </a>
                  <a href="https://twitter.com/Onlymusix_NFT">
                    <img className="w-7 flex" src={x}></img>
                  </a>
                </div>
              </div>
            </div>
            <hr className="w-max-content my-4 border border-white lg:ml-1" />
            <div className="flex flex-col pl-1 mx-auto md:mx-0">
              <span className="text-white font-onest font-medium">
                {t("footer.onlytechProduct")}
              </span>{" "}
              <span className="text-white font-onest font-medium">
                {t("footer.vat")}: 04346880240
              </span>{" "}
              <span className="text-white font-onest font-medium">
                {t("footer.contactUs")}{" "}
                <a href="mailto:info@onlymusix.com"> sales@popupevents.xyz</a>
              </span>{" "}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FooterComponent;
