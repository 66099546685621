import logo from "../logo.svg";
import "../App.scss";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
//Style
import "tailwindcss/tailwind.css";
import "../index.css";
import { useTranslation } from "react-i18next";

//components
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
function Privacy() {
  const { t, i18n } = useTranslation("common");
  const [languageInitialized, setLanguageInitialized] = useState(false);

  // Set the initial language based on the URL hash
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#it") {
      i18n.changeLanguage("it");
    } else if (hash === "#en") {
      i18n.changeLanguage("en");
    }
    setLanguageInitialized(true); // Mark language as initialized
  }, [i18n]);

  // Update the URL hash when the language changes
  useEffect(() => {
    if (i18n.language === "it") {
      window.location.hash = "#it";
    } else if (i18n.language === "en") {
      window.location.hash = "#en";
    }
  }, [i18n.language]);

  // Render the content only after the language has been initialized
  if (!languageInitialized) {
    return null; // or you can return a loading indicator if you prefer
  }
  return (
    <div className="App bg-grey1">
      <div className="h-full bg-grey1">
        <HeaderComponent />
      </div>
      <div className="h-fit">
        <div className="legal-container poap-legal  font-onest text-white text-left">
          {i18n.language === "it" && (
            <>
              <p dir="ltr">
                <strong>PRIVACY POLICY</strong>
              </p>
              <p dir="ltr">
                Questa Applicazione raccoglie alcuni Dati Personali dei propri
                Utenti.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Titolare del Trattamento dei Dati</strong>
              </p>
              <p dir="ltr">
                Onlytech Industries S.r.l., via Monte Grappa 6/L, Thiene (VI)
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Indirizzo E-mail del titolare:{" "}
                <a
                  className="text-accent underline"
                  href="mailto:info@onlytechindustries.com"
                >
                  info@onlytechindustries.com
                </a>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Dettagli sul trattamento dei Dati Personali</strong>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                I dati personali raccolti dal Sito sono i seguenti:
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Dati di navigazione</strong>: i dati di navigazione sono
                i dati raccolti automaticamente dal Sito durante la navigazione
                dell&rsquo;Utente, tra cui:
              </p>
              <ul
                style={{ listStyleType: "circle", paddingInlineStart: "48px" }}
              >
                <li dir="ltr">Indirizzo IP;</li>
                <li dir="ltr">Dati di geolocalizzazione;</li>
                <li dir="ltr">Dati relativi al browser utilizzato;</li>
                <li dir="ltr">
                  Dati relativi al sistema operativo utilizzato;
                </li>
                <li dir="ltr">
                  Dati relativi alla data e all&rsquo;ora della visita;
                </li>
                <li dir="ltr">Dati relativi alle pagine visitate.</li>
              </ul>
              <p dir="ltr">
                <strong>Dati forniti volontariamente dall&rsquo;Utente</strong>:
                i dati forniti volontariamente dall&rsquo;Utente sono i dati che
                l&rsquo;Utente fornisce al Sito compilando i form presenti sul
                Sito, tra cui:
              </p>
              <ul
                style={{ listStyleType: "circle", paddingInlineStart: "48px" }}
              >
                <li dir="ltr">Nome e cognome;</li>
                <li dir="ltr">Indirizzo e-mail;</li>
              </ul>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>
                  I dati personali raccolti dal Sito sono trattati per le
                  seguenti finalit&agrave;:
                </strong>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Fornire all&rsquo;Utente i servizi richiesti:</strong> i
                dati personali raccolti tramite i form presenti sul Sito sono
                trattati per fornire all&rsquo;Utente i servizi richiesti, come
                ad esempio la registrazione al Sito, l&rsquo;acquisto di
                prodotti o servizi, o l&rsquo;iscrizione alla newsletter.
              </p>
              <p dir="ltr">
                <strong>Migliorare la fruibilit&agrave; del Sito:</strong> i
                dati personali raccolti tramite i cookies sono trattati per
                migliorare la fruibilit&agrave; del Sito, come ad esempio per
                personalizzare la navigazione dell&rsquo;Utente o per mostrare
                contenuti pi&ugrave; pertinenti.
              </p>
              <p dir="ltr">
                <strong>
                  Inviare all&rsquo;Utente newsletter e comunicazioni
                  promozionali:
                </strong>{" "}
                i dati personali raccolti tramite Brevo sono trattati per
                inviare all&rsquo;Utente newsletter e comunicazioni
                promozionali.
              </p>
              <p dir="ltr">
                <strong>Tracciare le visite al Sito:</strong> i dati personali
                raccolti tramite Google Analytics sono trattati per tracciare le
                visite al Sito, come ad esempio per monitorare l&rsquo;utilizzo
                del Sito e per migliorare l&rsquo;esperienza degli Utenti.
              </p>
              <p dir="ltr">
                <strong>Effettuare pubblicit&agrave; mirata</strong>: i dati
                personali raccolti tramite Google Ads e Facebook e Instagram
                sono trattati per effettuare pubblicit&agrave; mirata, come ad
                esempio per mostrare agli Utenti annunci pubblicitari pertinenti
                ai loro interessi.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Base giuridica del trattamento</strong>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Il trattamento dei dati personali raccolti dal Sito &egrave;
                basato sulle seguenti basi giuridiche:
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Consenso dell&rsquo;Utente</strong>: il trattamento dei
                dati personali raccolti attraverso i form presenti sul Sito
                &egrave; basato sul consenso dell&rsquo;Utente, che viene
                espresso mediante l&rsquo;accettazione della presente Policy.
              </p>
              <p dir="ltr">
                <strong>Consenso dell&rsquo;Utente:</strong> il trattamento dei
                dati personali raccolti tramite i cookies &egrave; basato sul
                legittimo interesse del Titolare del trattamento a migliorare la
                fruibilit&agrave; del Sito.
              </p>
              <p dir="ltr">
                <strong>Consenso dell&rsquo;Utente: </strong>il trattamento dei
                dati personali raccolti tramite Brevo &egrave; basato sul
                consenso dell&rsquo;Utente, che viene espresso mediante
                l&rsquo;accettazione della presente Policy.
              </p>
              <p dir="ltr">
                <strong>Consenso dell&rsquo;Utente: </strong>il trattamento dei
                dati personali raccolti tramite Google Analytics &egrave; basato
                sul legittimo interesse del Titolare del trattamento a tracciare
                le visite al Sito.
              </p>
              <p dir="ltr">
                <strong>Consenso dell&rsquo;Utente: </strong>il trattamento dei
                dati personali raccolti tramite Google Ads &egrave; basato sul
                consenso dell&rsquo;Utente, che viene espresso mediante
                l&rsquo;accettazione delle cookie policy di Google Ads.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                &Egrave; comunque sempre possibile richiedere al Titolare di
                chiarire la concreta base giuridica di ciascun trattamento ed in
                particolare di specificare se il trattamento sia basato sulla
                legge, previsto da un contratto o necessario per concludere un
                contratto.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>
                  Modalit&agrave; e luogo del trattamento dei Dati raccolti
                </strong>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Modalit&agrave; di trattamento</strong>
              </p>
              <p dir="ltr">
                Il Titolare adotta le opportune misure di sicurezza volte ad
                impedire l&rsquo;accesso, la divulgazione, la modifica o la
                distruzione non autorizzate dei Dati Personali.
              </p>
              <p dir="ltr">
                Il trattamento viene effettuato mediante strumenti informatici
                e/o telematici, con modalit&agrave; organizzative e con logiche
                strettamente correlate alle finalit&agrave; indicate. Oltre al
                Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri
                soggetti coinvolti nell&rsquo;organizzazione di questa
                Applicazione (personale amministrativo, commerciale, marketing,
                legali, amministratori di sistema) ovvero soggetti esterni (come
                fornitori di servizi tecnici terzi, corrieri postali, hosting
                provider, societ&agrave; informatiche, agenzie di comunicazione)
                nominati anche, se necessario, Responsabili del Trattamento da
                parte del Titolare. L&rsquo;elenco aggiornato dei Responsabili
                potr&agrave; sempre essere richiesto al Titolare del
                Trattamento.
              </p>

              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>
                  Informativa sull’uso limitato delle API di Google
                </strong>
              </p>
              <p dir="ltr">
                Popup Events utilizza i servizi API di Google per abilitare la
                funzione di autorizzazione dell’utente per l’accesso
                all’applicazione. L’utilizzo e il trasferimento da parte di
                Popup Events a qualsiasi altra applicazione delle informazioni
                ricevute dalle API di Google rispetterà le{" "}
                <a
                  className="text-accent"
                  href="https://developers.google.com/terms/api-services-user-data-policy"
                >
                  Norme sui dati degli utenti del servizio API di Google
                </a>
                ,{" "}
                <a
                  className="text-accent"
                  href="https://support.google.com/cloud/answer/9110914#explain-types"
                >
                  compresi i Requisiti di utilizzo limitato
                </a>{" "}
                .
              </p>
              <p>&nbsp;</p>

              <p>
                Popup Events richiede l’accesso a tre{" "}
                <a
                  className="text-accent"
                  href="https://developers.google.com/identity/protocols/oauth2"
                >
                  Google OAuth2 API
                </a>
                :
              </p>
              <ul
                style={{ listStyleType: "number", paddingInlineStart: "48px" }}
              >
                {" "}
                <li>
                  <a
                    className="text-accent "
                    href="https://www.googleapis.com/auth/userinfo.email"
                  >
                    https://www.googleapis.com/auth/userinfo.email:{" "}
                  </a>{" "}
                  Questa API permette all’app di vedere solo l’indirizzo email
                  principale del tuo account Google.
                </li>
                <li>
                  <a
                    className="text-accent "
                    href="  https://www.googleapis.com/auth/userinfo.profile:"
                  >
                    https://www.googleapis.com/auth/userinfo.profile:{" "}
                  </a>{" "}
                  Questa API permette all’app di vedere alcune delle tue
                  informazioni personali, incluso qualsiasi elemento del tuo
                  profilo che hai reso pubblico. Potrebbe includere nome,
                  cognome, immagine del profilo e altre informazioni che hai
                  scelto di condividere.
                </li>
                <li>
                  <strong> openid:</strong> Questa API consente di associarti
                  alle tue informazioni personali su Google. In pratica, serve a
                  confermare che sei tu la persona che sta tentando di accedere
                  all’app utilizzando il tuo account Google.
                </li>
              </ul>
              <p>&nbsp;</p>

              <p dir="ltr">
                <strong>
                  {" "}
                  Perché Popup Events Events richiede queste informazioni?
                </strong>
              </p>
              <ul style={{ listStyleType: "disc", paddingInlineStart: "48px" }}>
                {" "}
                <li>
                  <strong>Creazione dell’account:</strong> Per creare un account
                  Popup Events Events se non ne hai già uno.
                </li>
                <li>
                  <strong>Autenticazione:</strong> Per verificare la tua
                  identità quando accedi all’app.
                </li>
              </ul>

              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Base giuridica del trattamento</strong>
              </p>
              <p dir="ltr">
                Il Titolare tratta Dati Personali relativi all&rsquo;Utente in
                caso sussista una delle seguenti condizioni:
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                l&rsquo;Utente ha prestato il consenso per una o pi&ugrave;
                finalit&agrave; specifiche; Nota: in alcuni ordinamenti il
                Titolare pu&ograve; essere autorizzato a trattare Dati Personali
                senza che debba sussistere il consenso dell&rsquo;Utente o
                un&rsquo;altra delle basi giuridiche specificate di seguito,
                fino a quando l&rsquo;Utente non si opponga
                (&ldquo;opt-out&rdquo;) a tale trattamento. Ci&ograve; non
                &egrave; tuttavia applicabile qualora il trattamento di Dati
                Personali sia regolato dalla legislazione europea in materia di
                protezione dei Dati Personali;
              </p>
              <p dir="ltr">
                il trattamento &egrave; necessario all&rsquo;esecuzione di un
                contratto con l&rsquo;Utente e/o all&rsquo;esecuzione di misure
                precontrattuali;
              </p>
              <p dir="ltr">
                il trattamento &egrave; necessario per adempiere un obbligo
                legale al quale &egrave; soggetto il Titolare;
              </p>
              <p dir="ltr">
                il trattamento &egrave; necessario per l&rsquo;esecuzione di un
                compito di interesse pubblico o per l&rsquo;esercizio di
                pubblici poteri di cui &egrave; investito il Titolare;
              </p>
              <p dir="ltr">
                il trattamento &egrave; necessario per il perseguimento del
                legittimo interesse del Titolare o di terzi.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Luogo</strong>
              </p>
              <p dir="ltr">
                I Dati sono trattati presso le sedi operative del Titolare ed in
                ogni altro luogo in cui le parti coinvolte nel trattamento siano
                localizzate. Per ulteriori informazioni, contatta il Titolare.
              </p>
              <p dir="ltr">
                I Dati Personali dell&rsquo;Utente potrebbero essere trasferiti
                in un paese diverso da quello in cui l&rsquo;Utente si trova.
                Per ottenere ulteriori informazioni sul luogo del trattamento
                l&rsquo;Utente pu&ograve; fare riferimento alla sezione relativa
                ai dettagli sul trattamento dei Dati Personali.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>
                  L&rsquo;Utente ha diritto a ottenere informazioni in merito
                  alla base giuridica del trasferimento di Dati al di fuori
                  dell&rsquo;Unione Europea o ad un&rsquo;organizzazione
                  internazionale di diritto internazionale pubblico o costituita
                  da due o pi&ugrave; paesi, come ad esempio l&rsquo;ONU,
                  nonch&eacute; in merito alle misure di sicurezza adottate dal
                  Titolare per proteggere i Dati.
                </strong>
              </p>

              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>
                  L&rsquo;Utente pu&ograve; verificare se abbia luogo uno dei
                  trasferimenti appena descritti esaminando la sezione di questo
                  documento relativa ai dettagli sul trattamento di Dati
                  Personali o chiedere informazioni al Titolare contattandolo
                  agli estremi riportati in apertura.
                </strong>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Periodo di conservazione</strong>
              </p>
              <p dir="ltr">
                I Dati sono trattati e conservati per il tempo richiesto dalle
                finalit&agrave; per le quali sono stati raccolti.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Pertanto</strong>:
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                I Dati Personali raccolti per scopi collegati
                all&rsquo;esecuzione di un contratto tra il Titolare e
                l&rsquo;Utente saranno trattenuti sino a quando sia completata
                l&rsquo;esecuzione di tale contratto.
              </p>
              <p dir="ltr">
                I Dati Personali raccolti per finalit&agrave; riconducibili
                all&rsquo;interesse legittimo del Titolare saranno trattenuti
                sino al soddisfacimento di tale interesse. L&rsquo;Utente
                pu&ograve; ottenere ulteriori informazioni in merito
                all&rsquo;interesse legittimo perseguito dal Titolare nelle
                relative sezioni di questo documento o contattando il Titolare.
              </p>
              <p dir="ltr">
                Quando il trattamento &egrave; basato sul consenso
                dell&rsquo;Utente, il Titolare pu&ograve; conservare i Dati
                Personali pi&ugrave; a lungo sino a quando detto consenso non
                venga revocato. Inoltre, il Titolare potrebbe essere obbligato a
                conservare i Dati Personali per un periodo pi&ugrave; lungo in
                ottemperanza ad un obbligo di legge o per ordine di
                un&rsquo;autorit&agrave;.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Al termine del periodo di conservazione i Dati Personali saranno
                cancellati. Pertanto, allo spirare di tale termine il diritto di
                accesso, cancellazione, rettificazione ed il diritto alla
                portabilit&agrave; dei Dati non potranno pi&ugrave; essere
                esercitati.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>
                  Finalit&agrave; del Trattamento dei Dati raccolti
                </strong>
              </p>
              <p dir="ltr">
                I Dati dell&rsquo;Utente sono raccolti per consentire al
                Titolare di fornire i propri Servizi, cos&igrave; come per le
                seguenti finalit&agrave;: Statistica, Gestione della raccolta
                dati e dei sondaggi online e Contattare l&rsquo;Utente.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Per ottenere ulteriori informazioni dettagliate sulle
                finalit&agrave; del trattamento e sui Dati Personali
                concretamente rilevanti per ciascuna finalit&agrave;,
                l&rsquo;Utente pu&ograve; fare riferimento alle relative sezioni
                di questo documento.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Dettagli sul trattamento dei Dati Personali</strong>
              </p>
              <p dir="ltr">
                I Dati Personali sono raccolti per le seguenti finalit&agrave;
                ed utilizzando i seguenti servizi:
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Contattare l&rsquo;Utente</strong>
              </p>
              <p dir="ltr">Modulo di contatto (questa Applicazione)</p>
              <p dir="ltr">
                L&rsquo;Utente, compilando con i propri Dati il modulo di
                contatto, acconsente al loro utilizzo per rispondere alle
                richieste di informazioni, di preventivo, o di qualunque altra
                natura indicata dall&rsquo;intestazione del modulo.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">Dati</p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Brevo SAS &ndash; Politique de confidentialit&eacute;
              </p>
              <p dir="ltr">55, rue d&rsquo;Amsterdam 75008 Paris, France</p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>
                  Interazione con social network e piattaforme esterne
                </strong>
              </p>
              <p dir="ltr">
                Questi servizi permettono di effettuare interazioni con i social
                network, o con altre piattaforme esterne, direttamente dalle
                pagine di questa Applicazione.
              </p>
              <p dir="ltr">
                Le interazioni e le informazioni acquisite da questa
                Applicazione sono in ogni caso soggette alle impostazioni
                privacy dell&rsquo;Utente relative ad ogni social network.
              </p>
              <p dir="ltr">
                Luogo del trattamento : USA &ndash; &nbsp;Privacy Policy
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">Remarketing e Behavioral Targeting</p>
              <p dir="ltr">
                Questi servizi consentono a questa Applicazione ed ai suoi
                partner di comunicare, ottimizzare e servire annunci
                pubblicitari basati sull&rsquo;utilizzo passato di questa
                Applicazione da parte dell&rsquo;Utente. Questa attivit&agrave;
                viene effettuata tramite il tracciamento dei Dati di Utilizzo e
                l&rsquo;uso di Cookie, informazioni che vengono trasferite ai
                partner a cui l&rsquo;attivit&agrave; di remarketing e
                behavioral targeting &egrave; collegata.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">Facebook Remarketing (Facebook, Inc.)</p>
              <p dir="ltr">
                Facebook Remarketing &egrave; un servizio di Remarketing e
                Behavioral Targeting fornito da Facebook, Inc. che collega
                l&rsquo;attivit&agrave; di questa Applicazione con il network di
                advertising Facebook.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Dati personali raccolti: Cookie e Dati di utilizzo.
              </p>
              <p dir="ltr">
                Luogo del trattamento : USA &ndash; &nbsp;Privacy Policy
                &nbsp;&ndash; &nbsp;Opt Out
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p dir="ltr">Google Analytics (Google Ireland Limited)</p>
              <p dir="ltr">
                Google Analytics &egrave; un servizio di analisi web fornito da
                Google Ireland Limited (&ldquo;Google&rdquo;). Google utilizza i
                Dati Personali raccolti allo scopo di tracciare ed esaminare
                l&rsquo;utilizzo di questa Applicazione, compilare report e
                condividerli con gli altri servizi sviluppati da Google.
              </p>
              <p dir="ltr">
                Google potrebbe utilizzare i Dati Personali per contestualizzare
                e personalizzare gli annunci del proprio network pubblicitario.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Dati Personali raccolti: Cookie; Dati di utilizzo.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Luogo del trattamento: Irlanda &ndash; &nbsp;Privacy Policy
                &nbsp;&ndash; &nbsp;Opt Out . Soggetto aderente al Privacy
                Shield.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">Diritti dell&rsquo;Utente</p>
              <p dir="ltr">
                Gli Utenti possono esercitare determinati diritti con
                riferimento ai Dati trattati dal Titolare.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">In particolare, l&rsquo;Utente ha il diritto di:</p>
              <p>&nbsp;</p>
              <ul
                style={{ listStyleType: "circle", paddingInlineStart: "48px" }}
              >
                <li dir="ltr">
                  <p dir="ltr">
                    revocare il consenso in ogni momento. L&rsquo;Utente
                    pu&ograve; revocare il consenso al trattamento dei propri
                    Dati Personali precedentemente espresso.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    opporsi al trattamento dei propri Dati. L&rsquo;Utente
                    pu&ograve; opporsi al trattamento dei propri Dati quando
                    esso avviene su una base giuridica diversa dal consenso.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    accedere ai propri Dati.&nbsp;L&rsquo;Utente ha diritto ad
                    ottenere informazioni sui Dati trattati dal Titolare, su
                    determinati aspetti del trattamento ed a ricevere una copia
                    dei Dati trattati.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    verificare e chiedere la rettificazione.&nbsp;L&rsquo;Utente
                    pu&ograve; verificare la correttezza dei propri Dati e
                    richiederne l&rsquo;aggiornamento o la correzione.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    ottenere la limitazione del trattamento.&nbsp;Quando
                    ricorrono determinate condizioni, l&rsquo;Utente pu&ograve;
                    richiedere la limitazione del trattamento dei propri Dati.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    ottenere la cancellazione o rimozione dei propri Dati
                    Personali.&nbsp;&nbsp;Quando ricorrono determinate
                    condizioni, l&rsquo;Utente pu&ograve; richiedere la
                    cancellazione dei propri Dati da parte del Titolare.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    ricevere i propri Dati o farli trasferire ad altro
                    titolare.&nbsp; L&rsquo;Utente ha diritto di ricevere i
                    propri Dati in formato strutturato, di uso comune e
                    leggibile da dispositivo automatico e, ove tecnicamente
                    fattibile, di ottenerne il trasferimento senza ostacoli ad
                    un altro titolare.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    proporre reclamo. L&rsquo;Utente pu&ograve; proporre un
                    reclamo all&rsquo;autorit&agrave; di controllo della
                    protezione dei dati personali competente o agire in sede
                    giudiziale.
                  </p>
                </li>
              </ul>
              <p>&nbsp;</p>
              <p dir="ltr">Dettagli sul diritto di opposizione</p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Quando i Dati Personali sono trattati nell&rsquo;interesse
                pubblico, nell&rsquo;esercizio di pubblici poteri di cui
                &egrave; investito il Titolare oppure per perseguire un
                interesse legittimo del Titolare, gli Utenti hanno diritto ad
                opporsi al trattamento
              </p>
              <p dir="ltr">
                <strong>Log di sistema e manutenzione</strong>
              </p>
              <p dir="ltr">
                Per necessit&agrave; legate al funzionamento ed alla
                manutenzione, questa Applicazione e gli eventuali servizi terzi
                da essa utilizzati potrebbero raccogliere log di sistema, ossia
                file che registrano le interazioni e che possono contenere anche
                Dati Personali, quali l&rsquo;indirizzo IP Utente.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Informazioni non contenute in questa policy</strong>
              </p>
              <p dir="ltr">
                Ulteriori informazioni in relazione al trattamento dei Dati
                Personali potranno essere richieste in qualsiasi momento al
                Titolare del Trattamento utilizzando gli estremi di contatto.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>
                  Risposta alle richieste &ldquo;Do Not Track&rdquo;
                </strong>
              </p>
              <p dir="ltr">
                Questa Applicazione supporta le richieste &ldquo;Do Not
                Track&rdquo;.
              </p>
              <p dir="ltr">
                Per scoprire se gli eventuali servizi di terze parti utilizzati
                le supportino, l&rsquo;Utente &egrave; invitato a consultare le
                rispettive privacy policy.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Modifiche a questa privacy policy</strong>
              </p>
              <p dir="ltr">
                Il Titolare del Trattamento si riserva il diritto di apportare
                modifiche alla presente privacy policy in qualunque momento
                dandone informazione agli Utenti su questa pagina e, se
                possibile, su questa Applicazione nonch&eacute;, qualora
                tecnicamente e legalmente fattibile, inviando una notifica agli
                Utenti attraverso uno degli estremi di contatto di cui &egrave;
                in possesso il Titolare. Si prega dunque di consultare
                regolarmente questa pagina, facendo riferimento alla data di
                ultima modifica indicata in fondo.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Qualora le modifiche interessino trattamenti la cui base
                giuridica &egrave; il consenso, il Titolare provveder&agrave; a
                raccogliere nuovamente il consenso dell&rsquo;Utente, se
                necessario.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Definizioni e riferimenti legali</strong>
              </p>
              <p dir="ltr">
                <strong>Dati Personali (o Dati)</strong>
              </p>
              <p dir="ltr">
                Costituisce dato personale qualunque informazione che,
                direttamente o indirettamente, anche in collegamento con
                qualsiasi altra informazione, ivi compreso un numero di
                identificazione personale, renda identificata o identificabile
                una persona fisica.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Dati di Utilizzo</strong>
              </p>
              <p dir="ltr">
                Sono le informazioni raccolte automaticamente attraverso questa
                Applicazione (anche da applicazioni di parti terze integrate in
                questa Applicazione), tra cui: gli indirizzi IP o i nomi a
                dominio dei computer utilizzati dall&rsquo;Utente che si
                connette con questa Applicazione, gli indirizzi in notazione URI
                (Uniform Resource Identifier), l&rsquo;orario della richiesta,
                il metodo utilizzato nell&rsquo;inoltrare la richiesta al
                server, la dimensione del file ottenuto in risposta, il codice
                numerico indicante lo stato della risposta dal server (buon
                fine, errore, ecc.) il paese di provenienza, le caratteristiche
                del browser e del sistema operativo utilizzati dal visitatore,
                le varie connotazioni temporali della visita (ad esempio il
                tempo di permanenza su ciascuna pagina) e i dettagli relativi
                all&rsquo;itinerario seguito all&rsquo;interno
                dell&rsquo;Applicazione, con particolare riferimento alla
                sequenza delle pagine consultate, ai parametri relativi al
                sistema operativo e all&rsquo;ambiente informatico
                dell&rsquo;Utente.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Utente</strong>
              </p>
              <p dir="ltr">
                L&rsquo;individuo che utilizza questa Applicazione che, salvo
                ove diversamente specificato, coincide con l&rsquo;Interessato.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Interessato</strong>
              </p>
              <p dir="ltr">
                La persona fisica cui si riferiscono i Dati Personali.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Responsabile del Trattamento (o Responsabile)</strong>
              </p>
              <p dir="ltr">
                La persona fisica, giuridica, la pubblica amministrazione e
                qualsiasi altro ente che tratta dati personali per conto del
                Titolare, secondo quanto esposto nella presente privacy policy.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Titolare del Trattamento (o Titolare)</strong>
              </p>
              <p dir="ltr">
                La persona fisica o giuridica, l&rsquo;autorit&agrave; pubblica,
                il servizio o altro organismo che, singolarmente o insieme ad
                altri, determina le finalit&agrave; e i mezzi del trattamento di
                dati personali e gli strumenti adottati, ivi comprese le misure
                di sicurezza relative al funzionamento ed alla fruizione di
                questa Applicazione. Il Titolare del Trattamento, salvo quanto
                diversamente specificato, &egrave; il titolare di questa
                Applicazione.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Questa Applicazione</strong>
              </p>
              <p dir="ltr">
                Lo strumento hardware o software mediante il quale sono raccolti
                e trattati i Dati Personali degli Utenti.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Servizio</strong>
              </p>
              <p dir="ltr">
                Il Servizio fornito da questa Applicazione cos&igrave; come
                definito nei relativi termini (se presenti) su quest
                sito/applicazione.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Unione Europea (o UE)</strong>
              </p>
              <p dir="ltr">
                Salvo ove diversamente specificato, ogni riferimento
                all&rsquo;Unione Europea contenuto in questo documento si
                intende esteso a tutti gli attuali stati membri
                dell&rsquo;Unione Europea e dello Spazio Economico Europeo.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Cookie</strong>
              </p>
              <p dir="ltr">
                Piccola porzione di dati conservata all&rsquo;interno del
                dispositivo dell&rsquo;Utente.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Riferimenti legali</strong>
              </p>
              <p dir="ltr">
                La presente informativa privacy &egrave; redatta sulla base di
                molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14
                del Regolamento (UE) 2016/679.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Ove non diversamente specificato, questa informativa privacy
                riguarda esclusivamente questa Applicazione.
              </p>
            </>
          )}
          {i18n.language === "en" && (
            <>
              <p dir="ltr">
                <strong>PRIVACY POLICY</strong>
              </p>
              <p dir="ltr">
                This Application collects some Personal Data from its Users.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Data Controller</strong>
              </p>
              <p dir="ltr">
                Onlytech Industries Srl, via Monte Grappa 6/L, Thiene (VI)
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Owner's email address:{" "}
                <a
                  className="text-accent underline"
                  href="mailto:info@onlytechindustries.com"
                >
                  info@onlytechindustries.com
                </a>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Details on the processing of Personal Data</strong>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                The personal data collected by the Site are as follows:
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Browsing data</strong>: Browsing data is data collected
                automatically by the Site during the User's browsing, including
              </p>
              <ul
                style={{ listStyleType: "circle", paddingInlineStart: "48px" }}
              >
                <li dir="ltr">IP address;</li>
                <li dir="ltr">Geolocation data;</li>
                <li dir="ltr">Data relating to the browser used;</li>
                <li dir="ltr">Data relating to the operating system used;</li>
                <li dir="ltr">
                  Data relating to the date and time of the visit;
                </li>
                <li dir="ltr">Data relating to pages visited.</li>
              </ul>
              <p dir="ltr">
                <strong>Data provided voluntarily by the User </strong>: the
                data provided voluntarily by the User is the data that the User
                provides to the Site by filling in the forms on the Site,
                including:
              </p>
              <ul
                style={{ listStyleType: "circle", paddingInlineStart: "48px" }}
              >
                <li dir="ltr">Name and surname;</li>
                <li dir="ltr">Email address;</li>
              </ul>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>
                  The personal data collected by the Site are processed for the
                  following purposes:
                </strong>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Provide the User with the requested services:</strong>{" "}
                the personal data collected through the forms on the Site are
                processed to provide the User with the requested services, such
                as registration on the Site, purchase of products or services,
                or registration on the newsletter.
              </p>
              <p dir="ltr">
                <strong>Improve the usability of the Site:</strong> the personal
                data collected through cookies are processed to improve the
                usability of the Site, such as personalizing the User's
                navigation or showing more relevant content.
              </p>
              <p dir="ltr">
                <strong>
                  Send newsletters and promotional communications to the User:
                </strong>{" "}
                the personal data collected through Brevo are processed to send
                newsletters and promotional communications to the User.
              </p>
              <p dir="ltr">
                <strong>Track visits to the Site:</strong> the personal data
                collected through Google Analytics are processed to track visits
                to the Site, such as monitoring the use of the Site and
                improving the User experience.
              </p>
              <p dir="ltr">
                <strong>Conduct targeted advertising:</strong> the personal data
                collected through Google Ads, Facebook, and Instagram are
                processed to conduct targeted advertising, such as showing Users
                relevant ads based on their interests.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Legal basis for processing</strong>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                The processing of personal data collected from the Site is based
                on the following legal bases:
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>User consent:</strong> the processing of personal data
                collected through the forms on the Site is based on the User's
                consent, which is expressed by accepting this Policy.
              </p>
              <p dir="ltr">
                <strong>Legitimate interest:</strong> the processing of personal
                data collected through cookies is based on the legitimate
                interest of the Data Controller to improve the usability of the
                Site.
              </p>
              <p dir="ltr">
                <strong>User consent:</strong> the processing of personal data
                collected through Brevo is based on the User's consent, which is
                expressed by accepting this Policy.
              </p>
              <p dir="ltr">
                <strong>Legitimate interest:</strong> the processing of personal
                data collected through Google Analytics is based on the
                legitimate interest of the Data Controller to track visits to
                the Site.
              </p>
              <p dir="ltr">
                <strong>User consent:</strong> the processing of personal data
                collected through Google Ads is based on the User's consent,
                which is expressed by accepting Google's cookie policy.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                It is always possible to request the Data Controller to clarify
                the concrete legal basis of each processing operation, and in
                particular to specify whether the processing is based on the
                law, provided for by a contract, or necessary to conclude a
                contract.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>
                  Methods and place of processing of collected Data
                </strong>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Methods of processing</strong>
              </p>
              <p dir="ltr">
                The Data Controller adopts appropriate security measures to
                prevent unauthorized access, disclosure, modification, or
                destruction of Personal Data.
              </p>
              <p dir="ltr">
                Processing is carried out using IT and/or telematic tools, with
                organizational methods and logic strictly related to the
                purposes indicated. In addition to the Data Controller, in some
                cases, others involved in the organization of this Application
                (administrative, commercial, marketing, legal, system
                administrators) or external parties (such as third-party
                technical service providers, postal carriers, hosting providers,
                IT companies, communication agencies) may have access to the
                Data and may be appointed, if necessary, as Data Processors by
                the Data Controller. The updated list of Data Processors can
                always be requested from the Data Controller.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Google API Limited Use Disclosure</strong>
              </p>
              <p dir="ltr">
                Popup Events uses Google’s API Services to enable the user
                authorization feature for accessing the app. Popup Events’ use
                and transfer to any other app of information received from
                Google APIs will adhere to the{" "}
                <a
                  className="text-accent"
                  href="https://developers.google.com/terms/api-services-user-data-policy"
                >
                  Google API Service User Data Policy{" "}
                </a>
                ,{" "}
                <a
                  className="text-accent"
                  href="https://support.google.com/cloud/answer/9110914#explain-types"
                >
                  including the Limited Use Requirements.
                </a>
              </p>
              <p>&nbsp;</p>
              <p>
                Popup Events requests access to three{" "}
                <a
                  class="text-accent"
                  href="https://developers.google.com/identity/protocols/oauth2"
                >
                  Google OAuth2 APIs
                </a>
                :
              </p>
              <ul
                style={{ listStyleType: "number", paddingInlineStart: "48px" }}
              >
                <li>
                  <a
                    class="text-accent"
                    href="https://www.googleapis.com/auth/userinfo.email"
                  >
                    https://www.googleapis.com/auth/userinfo.email:
                  </a>{" "}
                  to see your primary Google Account email address.
                </li>
                <li>
                  <a
                    class="text-accent"
                    href="https://www.googleapis.com/auth/userinfo.profile"
                  >
                    https://www.googleapis.com/auth/userinfo.profile:
                  </a>{" "}
                  to see your personal info, including any personal info you’ve
                  made publicly available.
                </li>
                <li>
                  <strong>openid:</strong> to Associate you with your personal
                  info on Google.
                </li>
              </ul>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Why does Popup Events require this information?</strong>
              </p>
              <ul style={{ listStyleType: "disc", paddingInlineStart: "48px" }}>
                <li>
                  <strong>Account creation:</strong> To create a Popup Events
                  account if you do not already have one.
                </li>
                <li>
                  <strong>Authentication:</strong> To verify your identity when
                  you access the app.
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Legal basis for processing</strong>
              </p>
              <p dir="ltr">
                The Data Controller processes Personal Data relating to the User
                if one of the following conditions exists:
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                The User has given consent for one or more specific purposes.
                Note: In some jurisdictions, the Data Controller may be allowed
                to process Personal Data without the User's consent or any of
                the other legal bases specified below, until the User objects
                ("opts-out") to such processing. However, this is not applicable
                when the processing of Personal Data is governed by European
                data protection legislation.
              </p>
              <p dir="ltr">
                The processing is necessary for the performance of a contract
                with the User and/or for any pre-contractual obligations
                thereof.
              </p>
              <p dir="ltr">
                The processing is necessary for compliance with a legal
                obligation to which the Data Controller is subject.
              </p>
              <p dir="ltr">
                The processing is related to a task that is carried out in the
                public interest or in the exercise of official authority vested
                in the Data Controller.
              </p>
              <p dir="ltr">
                The processing is necessary for the purposes of the legitimate
                interests pursued by the Data Controller or by a third party.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Location</strong>
              </p>
              <p dir="ltr">
                The Data is processed at the Data Controller's operating offices
                and in any other places where the parties involved in the
                processing are located. For further information, please contact
                the Data Controller.
              </p>
              <p dir="ltr">
                The User's Personal Data may be transferred to a country other
                than the one in which the User is located. To obtain further
                information about the location of processing, the User can refer
                to the section detailing the processing of Personal Data.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>
                  The User has the right to obtain information regarding the
                  legal basis for Data transfers outside the European Union or
                  to an international organization governed by public
                  international law or set up by two or more countries, such as
                  the UN, as well as regarding the security measures adopted by
                  the Data Controller to protect the Data.
                </strong>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>
                  The User can verify if any of the transfers described above
                  are taking place by reviewing the section of this document
                  related to the details on the processing of Personal Data or
                  by contacting the Data Controller using the contact
                  information provided at the beginning.
                </strong>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Retention Period</strong>
              </p>
              <p dir="ltr">
                The Data is processed and stored for the time required for the
                purposes for which it was collected.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Therefore:</strong>
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Personal Data collected for purposes related to the execution of
                a contract between the Data Controller and the User will be
                retained until such contract has been fully performed.
              </p>
              <p dir="ltr">
                Personal Data collected for purposes related to the legitimate
                interest of the Data Controller will be retained as long as
                needed to fulfill such purposes. The User may obtain further
                information regarding the legitimate interest pursued by the
                Data Controller in the relevant sections of this document or by
                contacting the Data Controller.
              </p>
              <p dir="ltr">
                When the processing is based on the User's consent, the Data
                Controller may retain Personal Data for a longer period until
                such consent is revoked. Furthermore, the Data Controller may be
                obliged to retain Personal Data for a longer period in
                compliance with a legal obligation or by order of an authority.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                At the end of the retention period, Personal Data will be
                deleted. Therefore, the right to access, delete, rectify, and
                the right to data portability can no longer be exercised after
                the retention period expires.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Purposes of the Data Processing collected</strong>
              </p>
              <p dir="ltr">
                The User's Data is collected to allow the Data Controller to
                provide its Services, as well as for the following purposes:
                Statistics, Managing data collection and online surveys, and
                Contacting the User.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                To obtain further detailed information about the purposes of the
                processing and the Personal Data specifically relevant for each
                purpose, the User can refer to the relevant sections of this
                document.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Details on the processing of Personal Data</strong>
              </p>
              <p dir="ltr">
                Personal Data is collected for the following purposes and using
                the following services:
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Contacting the User</strong>
              </p>
              <p dir="ltr">Contact form (this Application)</p>
              <p dir="ltr">
                By filling in the contact form with their Data, the User
                consents to its use to respond to requests for information,
                quotes, or any other type of request as indicated by the form's
                header.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">Data</p>
              <p>&nbsp;</p>
              <p dir="ltr">Brevo SAS – Privacy Policy</p>
              <p dir="ltr">55, rue d’Amsterdam 75008 Paris, France</p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>
                  Interaction with social networks and external platforms
                </strong>
              </p>
              <p dir="ltr">
                These services allow interaction with social networks or other
                external platforms directly from the pages of this Application.
              </p>
              <p dir="ltr">
                The interactions and information acquired by this Application
                are in any case subject to the User's privacy settings for each
                social network.
              </p>
              <p dir="ltr">Place of processing: USA – Privacy Policy</p>
              <p>&nbsp;</p>
              <p dir="ltr">Remarketing and Behavioral Targeting</p>
              <p dir="ltr">
                These services allow this Application and its partners to
                communicate, optimize, and serve advertisements based on the
                User's past use of this Application. This activity is carried
                out through the tracking of Usage Data and the use of Cookies,
                information that is transferred to the partners to whom the
                remarketing and behavioral targeting activity is linked.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">Facebook Remarketing (Facebook, Inc.)</p>
              <p dir="ltr">
                Facebook Remarketing is a Remarketing and Behavioral Targeting
                service provided by Facebook, Inc. that connects the activity of
                this Application with the Facebook advertising network.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">Personal Data collected: Cookies and Usage Data.</p>
              <p dir="ltr">
                Place of processing: USA – Privacy Policy – Opt Out
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p dir="ltr">Google Analytics (Google Ireland Limited)</p>
              <p dir="ltr">
                Google Analytics is a web analysis service provided by Google
                Ireland Limited ("Google"). Google uses the Personal Data
                collected to track and examine the use of this Application,
                compile reports and share them with other services developed by
                Google.
              </p>
              <p dir="ltr">
                Google may use the Personal Data to contextualize and
                personalize the ads of its own advertising network.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">Personal Data collected: Cookies; Usage Data.</p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Place of processing: Ireland – Privacy Policy – Opt Out.
                Participant in the Privacy Shield.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">User Rights</p>
              <p dir="ltr">
                Users may exercise certain rights regarding their Data processed
                by the Data Controller.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">In particular, the User has the right to:</p>
              <p>&nbsp;</p>
              <ul
                style={{ listStyleType: "circle", paddingInlineStart: "48px" }}
              >
                <li dir="ltr">
                  <p dir="ltr">
                    Withdraw their consent at any time. The User can withdraw
                    consent to the processing of their Personal Data previously
                    given.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    Object to the processing of their Data. The User can object
                    to the processing of their Data when it is carried out on a
                    legal basis other than consent.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    Access their Data. The User has the right to obtain
                    information about their Data processed by the Data
                    Controller, on certain aspects of the processing and to
                    receive a copy of the Data processed.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    Verify and seek rectification. The User can verify the
                    accuracy of their Data and request that it be updated or
                    corrected.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    Restrict the processing of their Data. Under certain
                    circumstances, the User can request the restriction of the
                    processing of their Data.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    Have their Personal Data deleted or otherwise removed. Under
                    certain circumstances, the User can request the deletion of
                    their Data by the Data Controller.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    Receive their Data and have it transferred to another
                    controller. The User has the right to receive their Data in
                    a structured, commonly used, and machine-readable format
                    and, if technically feasible, to have it transmitted to
                    another controller without any hindrance.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    Lodge a complaint. The User can lodge a complaint with the
                    competent data protection authority or take legal action.
                  </p>
                </li>
              </ul>
              <p>&nbsp;</p>

              <p dir="ltr">Details on the right to object</p>
              <p>&nbsp;</p>
              <p dir="ltr">
                When Personal Data is processed in the public interest, in the
                exercise of public powers vested in the Controller, or for the
                legitimate interest of the Controller, Users have the right to
                object to the processing.
              </p>
              <p dir="ltr">
                <strong>System logs and maintenance</strong>
              </p>
              <p dir="ltr">
                For operational and maintenance purposes, this Application and
                any third-party services used by it may collect system logs,
                which are files that record interactions and may also contain
                Personal Data, such as the User's IP address.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Information not contained in this policy</strong>
              </p>
              <p dir="ltr">
                Further information regarding the processing of Personal Data
                can be requested from the Controller at any time using the
                contact details provided.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Response to “Do Not Track” requests</strong>
              </p>
              <p dir="ltr">
                This Application supports “Do Not Track” requests.
              </p>
              <p dir="ltr">
                To find out if any third-party services it uses honor these
                requests, Users are encouraged to consult their respective
                privacy policies.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Changes to this privacy policy</strong>
              </p>
              <p dir="ltr">
                The Controller reserves the right to make changes to this
                privacy policy at any time by notifying Users on this page and,
                if possible, within this Application, as well as, if technically
                and legally feasible, sending a notification to Users via any
                contact information the Controller has. It is strongly
                recommended to check this page often, referring to the date of
                the last modification listed at the bottom.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                If the changes affect processing activities whose legal basis is
                consent, the Controller will collect new consent from the User,
                if necessary.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Definitions and legal references</strong>
              </p>
              <p dir="ltr">
                <strong>Personal Data (or Data)</strong>
              </p>
              <p dir="ltr">
                Any information that directly, indirectly, or in connection with
                other information, including a personal identification number,
                allows for the identification or identifiability of a natural
                person.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Usage Data</strong>
              </p>
              <p dir="ltr">
                Information collected automatically through this Application (or
                third-party services employed in this Application), which can
                include: the IP addresses or domain names of the computers used
                by the User who connects with this Application, the URI (Uniform
                Resource Identifier) addresses, the time of the request, the
                method utilized to submit the request to the server, the size of
                the file received in response, the numerical code indicating the
                status of the server's answer (successful outcome, error, etc.),
                the country of origin, the features of the browser and the
                operating system utilized by the visitor, the various time
                details per visit (e.g., the time spent on each page), and the
                details about the path followed within the Application, with
                particular reference to the sequence of pages visited, and other
                parameters about the device's operating system and/or the User's
                IT environment.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>User</strong>
              </p>
              <p dir="ltr">
                The individual using this Application who, unless otherwise
                specified, coincides with the Data Subject.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Data Subject</strong>
              </p>
              <p dir="ltr">
                The natural person to whom the Personal Data refers.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Data Processor (or Data Supervisor)</strong>
              </p>
              <p dir="ltr">
                The natural or legal person, public authority, agency, or other
                body that processes Personal Data on behalf of the Controller,
                as described in this privacy policy.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Data Controller (or Controller)</strong>
              </p>
              <p dir="ltr">
                The natural or legal person, public authority, agency, or other
                body which, alone or jointly with others, determines the
                purposes and means of the processing of Personal Data, including
                the security measures concerning the operation and use of this
                Application. The Data Controller, unless otherwise specified, is
                the owner of this Application.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>This Application</strong>
              </p>
              <p dir="ltr">
                The means by which the Personal Data of the User is collected
                and processed.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Service</strong>
              </p>
              <p dir="ltr">
                The Service provided by this Application as described in the
                relative terms (if available) on this site/application.
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>European Union (or EU)</strong>
              </p>
              <p dir="ltr">
                Unless otherwise specified, any reference made within this
                document to the European Union includes all current member
                states to the European Union and the European Economic Area.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Cookies</strong>
              </p>
              <p dir="ltr">Small sets of data stored in the User's device.</p>
              <p>&nbsp;</p>
              <p dir="ltr">
                <strong>Legal references</strong>
              </p>
              <p dir="ltr">
                This privacy statement is prepared based on provisions of
                multiple legislations, including Art. 13/14 of Regulation (EU)
                2016/679 (General Data Protection Regulation).
              </p>
              <p>&nbsp;</p>
              <p dir="ltr">
                Unless otherwise stated, this privacy policy applies exclusively
                to this Application.
              </p>
            </>
          )}
        </div>
      </div>
      <div className=" w-full bg-grey">
        {/*footer */}
        <FooterComponent />
      </div>
    </div>
  );
}
export default Privacy;
