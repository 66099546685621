import ".././App.scss";
//Style
import "tailwindcss/tailwind.css";
import ".././index.css";
import React from "react"; // Removed unused imports
import logo from "../static/ICONA-APP-STORE-22.png";
import background from "../static/SFONDO-LANDING-POAP.png";
import logoblack from "../static/ICONA-APP-STORE-1.png";
import crowdphoto from "../static/hero1.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper"; // Import Swiper styles
import "swiper/css";
import makeyoureventpoap from "../static/SCRITTA-MAKE-YOUR-POAP.png";
import "swiper/css/pagination";
import swiperimg1 from "../static/ben-collin.jpg";
import swiperimg2 from "../static/alex-bracet.jpg";
// import required modules
import { useTranslation } from "react-i18next";

const HeroComponent = (props) => {
  const { t } = useTranslation("common");
  return (
    <>
      {/*first hero block */}
      <div className="poap-hero-background bg-cover bg-center overflow-hidden h-[150px] md:h-20 lg:h-25 flex flex-col md:flex-row m-auto bg-opacity-20">
        {/*title block */}
        <div className="w-full uppercase mx-auto pl-2 my-auto text-white font-bold font-flytrap">
          <img className="mx-auto md:ml-5 w-11/12 " src={makeyoureventpoap} />
        </div>
        {/*logo block */}
        <div className="hidden md:flex md:w-6/12">
          <img
            className="w-10 h-10 lg:h-15 lg:w-15 mx-auto my-5 md:my-auto rounded-xl shadowpoap"
            src={logoblack}
          ></img>
        </div>
      </div>
      {/*second hero block */}
      <div className="md:h-[165px] lg:h-[315px] h-fit mb-[-80px] md:mb-[0px] bg-grey1">
        <div className="w-full flex flex-row md:flex-row max-w-1440 mx-auto xxl:pt-5">
          <div className="hidden md:flex h-10 w-15 md:w-[440px] lg:w-25 ">
            <img
              className="relative bottom-[-25px] md:bottom-icon left-[-30px] md:left-[-25px] h-[250px] lg:h-18 rounded-2xl shadowpoap"
              src={crowdphoto}
            ></img>
          </div>
          <div className="m-1 mt-4 mx-auto ">
            <div className="mx-auto flex h-10 w-10 md:h-9.75 md:w-9.75 lg:h-12 lg:w-12 my-5 md:my-auto rounded-xl shadowpoap">
              <Swiper
                spaceBetween={80}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={true}
                modules={[Pagination, Autoplay]}
                className="mySwiper rounded-xl  "
              >
                <SwiperSlide>
                  <img src={swiperimg1} />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img className="h-full" src={swiperimg2} />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img src={crowdphoto} />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="hidden md:flex flex-col my-auto text-left w-full">
            <div className="my-auto px-2">
              <p className="text-xl xl:text-4xl pb-1 lg:pl-6 lg:pt-5 lg:text-xl text-white font-normal font-flytrap whitespace-pre">
                {t("promo.title")}
              </p>
              <p className="text-xs lg:px-6 lg:text-sm xxl:w-full xl:text-xl text-white font-normal font-onest xl:w-5/6">
                {t("promo.description")}
              </p>
            </div>
            <div className="my-auto px-2 pt-2">
              <p className="text-base pb-1 lg:pl-6 text-white font-normal font-flytrap whitespace-pre">
                {t("promo.title2")}
              </p>
              <p className="text-xs lg:px-6 lg:text-sm xxl:w-full xl:text-xl text-white font-normal font-onest xl:w-5/6">
                {t("promo.description2")}
              </p>
            </div>
          </div>
        </div>
        <div className="flex md:hidden flex-col my-auto text-left w-full">
          <div className="my-auto px-2">
            <p className="text-xl pb-1 lg:px-6 lg:text-xl text-white font-normal font-flytrap">
              {t("promo.title")}{" "}
            </p>
            <p className="text-xs lg:px-6 lg:text-sm text-white font-normal font-onest xl:w-5/6 xxl:w-2/3">
              {t("promo.description")}
            </p>
          </div>
          <div className="my-auto px-2">
            <p className="text-xs py-1 lg:px-6 lg:text-xl text-white font-normal font-flytrap">
              {t("promo.title2")}{" "}
            </p>
            <p className="text-xs lg:px-6 lg:text-sm text-white font-normal font-onest xl:w-5/6 xxl:w-2/3">
              {t("promo.description2")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroComponent;
